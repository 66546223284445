import React, { FC, useState, useEffect, SetStateAction } from 'react';

import styles from './index.module.css';

type CountdownProps = {
  unitColor: string;
  timeColor: string;
  countdown: boolean;
  countdownStartDate: string;
  countdownEndDate: string;
  setIsBetweenDates: React.Dispatch<SetStateAction<boolean>>;
};

export const CountdownTimer: FC<CountdownProps> = ({
  unitColor,
  timeColor,
  countdown,
  countdownStartDate,
  countdownEndDate,
  setIsBetweenDates,
}) => {
  const [countDown, setCountDown] = useState({ d: '00', h: '00', m: '00', s: '00' });

  const { d, h, m, s } = countDown;

  useEffect(
    function () {
      let intervalId = null;

      if (countdown) {
        const end = new Date(countdownEndDate);

        end.setHours(end.getHours());

        const endTs = end.getTime();

        setCountDown(calculateTime(endTs));

        intervalId = setInterval(function () {
          setCountDown(calculateTime(endTs));
          if (!isCurrentDateBetweenDates(countdownStartDate, countdownEndDate)) {
            setIsBetweenDates(false);
            clearInterval(intervalId);
          }
        }, 1000);
      }

      return function () {
        clearInterval(intervalId);
      };
    },
    [countdown, countdownEndDate, countdownStartDate, setIsBetweenDates],
  );

  return (
    <>
      <div className={styles.timer}>
        {d !== '00' && (
          <div className={styles.timeUnit}>
            <div
              className={styles.time}
              style={{
                color: timeColor,
              }}
            >
              {d}
            </div>
            <div
              className={styles.unit}
              style={{
                color: unitColor,
              }}
            >
              Day{d !== '01' && 's'}
            </div>
          </div>
        )}
        <div className={styles.timeUnit}>
          <div
            className={styles.time}
            style={{
              color: timeColor,
            }}
          >
            {h}
          </div>
          <div
            className={styles.unit}
            style={{
              color: unitColor,
            }}
          >
            Hour{h !== '01' && 's'}
          </div>
        </div>
        <div className={styles.timeUnit}>
          <div
            className={styles.time}
            style={{
              color: timeColor,
            }}
          >
            {m}
          </div>
          <div
            className={styles.unit}
            style={{
              color: unitColor,
            }}
          >
            Min{m !== '01' && 's'}
          </div>
        </div>
        {d === '00' && (
          <div className={styles.timeUnit}>
            <div
              className={styles.time}
              style={{
                color: timeColor,
              }}
            >
              {s}
            </div>
            <div
              className={styles.unit}
              style={{
                color: unitColor,
              }}
            >
              Second{s !== '01' && 's'}
            </div>
          </div>
        )}
      </div>
      <div
        className={styles.timerMobile}
        style={{
          color: unitColor,
        }}
      >
        {d !== '00' && (
          <>
            <div className={styles.unit}>{d}</div>
            <div className={styles.divider}>:</div>
          </>
        )}
        <div className={styles.unit}>{h}</div>
        <div className={styles.divider}>:</div>
        <div className={styles.unit}>{m}</div>
        <div className={styles.divider}>:</div>
        <div className={styles.unit}>{s}</div>
        <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_1236_55526)'>
            <path d='M5 12H19' stroke={unitColor} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
            <path d='M15 16L19 12' stroke={unitColor} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
            <path d='M15 8L19 12' stroke={unitColor} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
          </g>
          <defs>
            <clipPath id='clip0_1236_55526'>
              <rect fill='black' height='24' width='24' />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
};

function calculateTime(endTs) {
  const now = new Date();

  const nowTs = now.getTime();
  const remaining = endTs - nowTs;

  const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remaining / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((remaining / (1000 * 60)) % 60);
  const seconds = Math.floor((remaining / 1000) % 60);

  return {
    d: pad(days),
    h: pad(hours),
    m: pad(minutes),
    s: pad(seconds),
  };
}

function isCurrentDateBetweenDates(startDateString: string, endDateString: string): boolean {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('dev-discount-banner')) {
    return true;
  }
  const now = new Date(); // Current date and time
  const startDate = new Date(startDateString); // Convert start date string to Date object
  const endDate = new Date(endDateString); // Convert end date string to Date object

  // Check if 'now' is between the start and end dates
  return now >= startDate && now <= endDate;
}

function pad(number) {
  if (number < 10) {
    return `0${number < 0 ? '0' : number}`;
  }

  return `${number}`;
}
