/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { FC, useState, useEffect, useReducer } from 'react';

import { useRouter } from 'next/router';

import type { GlobalBannerData, GlobalBannerVariantStyles } from '@/types/globalBanner';

import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

import { CountdownTimer } from './countdownTimer';
import s from './index.module.css';

type GlobalBannerProps = {
  bannerData: GlobalBannerData;
  email: string | null;
  subscription: any;
};

type ReducerAction = {
  payload: string;
  value: ReducerState;
};

type ReducerState = {
  pid: string;
  imageUrl: string;
  breakpointPx: number;
  ctaText: string;
  isDefault: boolean;
  linkIsExternal: boolean;
  redirectUrl: string;
  styles: GlobalBannerVariantStyles;
  variantKey: string;
  variantKind: string;
};

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  const { payload, value } = action;
  switch (payload) {
    case 'UPDATE_STATE':
      return value;
    default:
      return state;
  }
};

export const GlobalBanner: FC<GlobalBannerProps> = ({ bannerData, email, subscription }) => {
  const defaultVariant = bannerData.variants.find(
    (variant) => variant.breakpointPx === 1200 && variant.variantKey === 'default',
  );
  const [isBetweenDates, setIsBetweenDates] = useState(
    isCurrentDateBetweenDates(bannerData.countdownStartDate, bannerData.countdownEndDate),
  );
  const [state, dispatch] = useReducer(reducer, {
    pid: defaultVariant?.pid ?? '',
    imageUrl: defaultVariant?.imageUrl ?? '',
    breakpointPx: defaultVariant?.breakpointPx ?? 0,
    ctaText: defaultVariant?.ctaText ?? '',
    isDefault: defaultVariant?.isDefault ?? true,
    linkIsExternal: defaultVariant?.linkIsExternal ?? false,
    redirectUrl: defaultVariant?.redirectUrl ?? '',
    styles: {
      banner_background_color: defaultVariant?.styles?.banner_background_color ?? '',
      cta_background_color: defaultVariant?.styles?.cta_background_color ?? '',
      cta_font_size: defaultVariant?.styles?.cta_font_size ?? '',
      cta_font_weight: defaultVariant?.styles?.cta_font_weight ?? '',
      cta_text_color: defaultVariant?.styles?.cta_text_color ?? '',
    },
    variantKey: defaultVariant?.variantKey ?? '',
    variantKind: defaultVariant?.variantKind ?? '',
  });

  const router = useRouter();

  const {
    pid,
    imageUrl,
    breakpointPx,
    ctaText,
    isDefault,
    linkIsExternal,
    redirectUrl,
    styles,
    variantKey,
    variantKind,
  } = state;

  useEffect(() => {
    let lastBreakpoint = -1; // Start with a non-matching value

    const handleResize = () => {
      const width = window.innerWidth;

      // Determine the new breakpoint
      let currentBreakpoint = -1;
      if (width >= 1200) {
        currentBreakpoint = 1200;
      } else if (width >= 550) {
        currentBreakpoint = 1000;
      } else {
        currentBreakpoint = 550;
      }

      // Skip updates if the breakpoint hasn't changed
      if (currentBreakpoint === lastBreakpoint) return;

      // Find the corresponding variant
      const currentVariant = bannerData.variants.find((variant) => variant.breakpointPx === currentBreakpoint);

      if (!currentVariant) return; // If no variant is found, skip the update

      // Update state only if there's a valid variant
      const value: ReducerState = {
        pid: currentVariant.pid,
        imageUrl: currentVariant.imageUrl,
        breakpointPx: currentVariant.breakpointPx,
        ctaText: currentVariant.ctaText,
        isDefault: currentVariant.isDefault,
        linkIsExternal: currentVariant.linkIsExternal,
        redirectUrl: currentVariant.redirectUrl,
        styles: currentVariant.styles,
        variantKey: currentVariant.variantKey,
        variantKind: currentVariant.variantKind,
      };

      dispatch({ payload: 'UPDATE_STATE', value });

      // Update the last breakpoint
      lastBreakpoint = currentBreakpoint;
    };

    // Trigger initial resize
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [bannerData]);

  const getRelativePath = (urlString: string): string => {
    return new URL(urlString).pathname;
  };

  function sendCtaEvent() {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      ...utmCookieData,
      bannerId: pid,
      redirectUrl,
      email: email,
      label: ctaText,
      location: 'Universal CTA Banner',
      path: window.location.pathname,
      subscriptionUuid: subscription?.id || null,
    });
  }

  if (!bannerData) return null;

  return (
    <div
      className={s.bannerContainer}
      onClick={() => {
        sendCtaEvent();
        linkIsExternal
          ? window.open(redirectUrl, '_blank', 'noopener,noreferrer')
          : router.push(getRelativePath(redirectUrl));
      }}
      style={{
        backgroundColor: styles.banner_background_color,
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      {bannerData.bannerType === 'countdown' && isBetweenDates ? (
        <CountdownTimer
          countdown={bannerData.bannerType === 'countdown'}
          countdownEndDate={bannerData.countdownEndDate}
          countdownStartDate={bannerData.countdownStartDate}
          setIsBetweenDates={setIsBetweenDates}
          timeColor={styles.cta_background_color}
          unitColor={styles.cta_text_color}
        />
      ) : (
        <a
          className={s.cta}
          style={{
            color: styles.cta_text_color,
            backgroundColor: styles.cta_background_color,
            fontSize: `${styles.cta_font_size}px`,
            fontWeight: styles.cta_font_weight,
          }}
        >
          {ctaText}
        </a>
      )}
    </div>
  );
};

function isCurrentDateBetweenDates(startDateString: string, endDateString: string): boolean {
  const now = new Date(); // Current date and time
  const startDate = new Date(startDateString); // Convert start date string to Date object
  const endDate = new Date(endDateString); // Convert end date string to Date object

  // Check if 'now' is between the start and end dates
  return now >= startDate && now <= endDate;
}
