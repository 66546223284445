import BannerDailyWire from '../../../public/images/version2/watch/BannerDailyWire.jpg';
import DailyWire from '../../../public/images/version2/watch/DailyWire.png';
import JordanPetersonHeroBanner from '../../../public/images/version2/watch/JordanPeterson_hero_banner.jpg';
import JordanPetersonThumbnail from '../../../public/images/version2/watch/JordanPeterson_thumbnail.jpg';
import Movies from '../../../public/images/version2/watch/Movies.png';
import PragerUHeroBanner from '../../../public/images/version2/watch/PragerU_hero_banner.jpg';
import PragerUThumbnail from '../../../public/images/version2/watch/PragerU_thumbnail.jpg';

export default {
  'daily-wire': [
    'The Ben Shapiro Show',
    'The Michael Knowles Show',
    'The Matt Walsh Show',
    'The Andrew Klavan Show',
    'The Comments Section',
    'The Comments Section with Reagan Conrad',
    'Crain & Company',
    'Election Wire',
    'The Sunday Special',
    'Michael &',
    'Facts',
    'Ben After Dark',
    'The Divided States of Biden',
    'What We Saw',
    'Daily Wire Backstage',
    'Michael’s Rules',
    'Town Hall',
    'Ben Shapiro’s Book Club',
    'Election Wire',
    'BreakAways',
    'The Search',
    'Yes or No',
    'Debunked',
    'Office Hours',
    'Enough',
    'DW Entertainment',
    'Candace Owens',
    'Convicting a Murderer',
    'Taboo with Candace Owens',
  ],

  'jordan-peterson': [
    'The Jordan B. Peterson Podcast',
    'The Gospels',
    'Success',
    'Foundations of the West',
    'Negotiation',
    'Dr. Jordan B. Peterson on Depression & Anxiety',
    'Exodus',
    'Dr. Jordan B. Peterson on Marriage',
    'Dragons, Monsters, and Men',
    'Vision & Destiny',
    'End of the World',
    'Logos & Literacy',
    'Maps of Meaning',
    'Biblical Series: Genesis',
    'Personality and Its Transformations',
    'We Who Wrestle With God Tour',
    'Dr. Jordan B. Peterson’s Beyond Order Tour',
    'A Tribute to Newfoundland',
  ],
  'prager-u': [
    'PragerU Master’s Program',
    '5-Minute Videos',
    'Fireside Chat with Dennis Prager',
    'Real Talk with Marissa',
    'Short Documentaries',
    'The Wrap Up',
    'Stories of Us',
    'Unapologetic with Amala',
    'Man on the Street',
    'Ami on the Loose',
    'The Book Club with Michael Knowles',
    'The Candace Owens Show',
    'Americanos',
    'Office Hours',
    'The Hustle',
    'Cash Course',
    'Around the World',
    'Unboxed, USA',
    'Street Smarts',
    'Guess or Mess',
    "Otto's Tales",
    "Leo & Layla's History Adventures",
    'Craftory',
    'How To',
    'TBH History',
  ],
  kids: ['Introducing DW Kids'],
};

export const channelList = [
  {
    name: 'The Daily Wire',
    id: 'daily-wire',
    banner: BannerDailyWire,
    image: DailyWire,
  },
  {
    name: 'Jordan Peterson',
    id: 'jordan-peterson',
    banner: JordanPetersonHeroBanner,
    image: JordanPetersonThumbnail,
  },
  {
    name: 'PragerU',
    id: 'prager-u',
    banner: PragerUHeroBanner,
    image: PragerUThumbnail,
  },
  {
    name: 'Movies',
    id: 'movies',
    banner: 'https://dailywireplus-v2.imgix.net/uploads/movies_banner_2024-11.png',
    image: Movies,
  },
  {
    external: true,
    id: 'bentkey',
    name: 'Bentkey',
    url: 'https://www.bentkey.com',
  },
];

export const DailyWireShows = [
  {
    id: 'cl9zzxux60fey0886nt8himsg',
    name: 'Election Wire',
    slug: 'election-wire',
    description:
      'Election Wire: Your home of 2024 campaign action from The Daily Wire. We track breaking news and analyze key developments during this election season.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cl9zzxux60fey0886nt8himsg-portraitImage-1721851850525.png',
  },
  {
    id: 'clux7tvdsujli0866kx474vsb',
    name: 'Michael &',
    slug: 'michael',
    description:
      'Take a break from mainstream politics with Michael Knowles as he journeys with you into the world of fascinating people with extraordinary testimonies. ‘Michael &’ is a long-form conversation series where discussions can lead anywhere, allowing guests to share their shocking stories and engage in civilized debates.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clux7tvdsujli0866kx474vsb-portraitImage-1712959455975.png',
  },
  {
    id: 'clteswcuc05ss07267px7kw92',
    name: 'Michael’s Rules',
    slug: 'michael-s-rules',
    description: 'Michael Knowles teaches you how to smoke a cigar like a true gentleman with Michael’s Rules.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clteswcuc05ss07267px7kw92-portraitImage-1709669201382.jpg',
  },
  {
    id: 'clalcaobc8aoi0822202jna89',
    name: 'Town Hall',
    slug: 'daily-wire-townhall',
    description:
      'Daily Wire Co-CEO, Jeremy Boreing, holds a Town Hall to announce the success and wins of DailyWire+ and charts an inspiring path forward.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clalcaobc8aoi0822202jna89-portraitImage-1668710064582.png',
  },
  {
    id: 'ckixsvamonvl40862ysxve50i',
    name: 'What We Saw',
    slug: 'apollo-11-what-we-saw',
    description:
      "Step back in time and immerse yourself in history like never before with Bill Whittle. Get ready to experience the pivotal moments that molded America in a whole new way. With captivating historical footage, you'll feel like you're right there in the thick of it all– without the woke filter. In Season 1, join the journey to the moon and witness humanity's triumph as they conquer the final frontier with the Apollo 11 mission. And in Season 2, delve into the tensions of the Cold War, where two vastly different ideologies shaped history on the battlefield of a decades-long conflict, where not a single shot was fired. Don't just read about history. Live it.",
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/ckixsvamonvl40862ysxve50i-portraitImage-1717448991774.png?81862.29999995232',
  },
  {
    id: 'clt4xp0dixocu0834zxzgu67j',
    name: 'Yes or No',
    slug: 'yes-or-no',
    description:
      'From politics and culture to religion and conspiracy theories, nothing is off-limits. Watch as Michael Knowles and his guests reveal their true opinions on the most controversial topics of our time.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clt4xp0dixocu0834zxzgu67j-portraitImage-1709155077459.png?555336.7000000011',
  },
];

export const JordanShows = [
  {
    id: 'cm3t7fgr4u1i10782a02ohidq',
    name: 'We Who Wrestle With God Tour',
    portraitImage: 'https://dailywireplus-v2.imgix.net/uploads/jbp-wwwwg-9x16-new-series.png',
    slug: 'we-who-wrestle-with-god-tour',
    description:
      'In his own version of a Socratic seminar, Dr. Jordan B. Peterson conducts arena-sized discussions pondering how the biblical and psychological intersect, exploring the questions and revelations that birthed his latest best-selling book, “We Who Wrestle With God”.',
  },
  {
    id: 'cm2z0eg4t2k130810cxyo0960',
    name: 'Success',
    portraitImage: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/9x16%20Success%20New%20Series.png',
    slug: 'success',
    description: `If you're in the throws of disappointment despite outward achievement, your lens for success might be broken. In his newest series from the Mastering Life collection, Dr. Peterson redefines conventional notions of success, offering a profound reevaluation of what it means to live a fulfilling life.`,
  },
  {
    id: 'cm18cldz0o8v30878y14y8jq0',
    name: 'Negotiation',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cm18cldz0o8v30878y14y8jq0-portraitImage-1730297825817.jpg?512309.200000003',
    slug: 'negotiation',
    description:
      'Master the art of negotiation with Dr. Jordan B. Peterson and transform your life. Learn to navigate personal and professional challenges, creating win-win scenarios in every interaction. Part of the "Mastering Life" catalog, this series reveals how mastering negotiation leads to a richer, more fulfilling existence.',
  },
  {
    id: 'cm114za4nmi0b09544zkyklis',
    name: 'Dr. Jordan B. Peterson on Depression & Anxiety',
    slug: 'dr-jordan-b-peterson-on-depression-anxiety',
    description:
      'Dr. Jordan B. Peterson reveals his revolutionary approach to conquering depression, anxiety, and resentment in this groundbreaking five-part series. See powerful psychotherapy techniques from his clinical work, uncover the roots of mental anguish, and arm yourself with tools to reclaim your life.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cm114za4nmi0b09544zkyklis-portraitImage-1726257362476.jpg?339628.60000002384',
  },
  {
    id: 'clzu59x6ffwhi0894fm90ajnv',
    name: 'Foundations of the West',
    slug: 'foundations-of-the-west',
    description:
      "Journey through the ancient cities that shaped the West. Discover the impact these civilizations had on our modern world. In this 5 part series, Dr. Jordan B. Peterson, accompanied by his esteemed colleagues, traverse iconic sites and unpack the profound impact these societies have had on shaping the western world. Through insightful discussions and up close examination of key sites that hosted historical accounts in man's story, Dr. Peterson and special guests are able to dissect the legacies these nations have made in standing the test of time.",
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clzu59x6ffwhi0894fm90ajnv-portraitImage-1723657676333.jpg?1614126.3999999762',
  },
  {
    id: 'clwf3rot4451a0870wprp4ynw',
    name: 'A Tribute to Newfoundland',
    slug: 'a-tribute-to-newfoundland',
    description:
      "Dr. Jordan B. Peterson and the late Rex Murphy explore Newfoundland, delving into its history, culture, and resilience. From John Cabot’s arrival to the fishing industry's decline, they highlight cultural breakthroughs and the kindness of Gander's residents after September 11. Dedicated to Rex Murphy, a true friend of Canada.",
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clwf3rot4451a0870wprp4ynw-portraitImage-1719438132102.png?61590.799999952316',
  },
  {
    id: 'cla329m2k42md0806b9gjumeo',
    name: 'Dr. Jordan B. Peterson’s Beyond Order Tour',
    slug: 'dr-jordan-b-peterson-s-beyond-order-tour',
    description: 'Join Jordan for his “Beyond Order: 12 More Rules for Life” 2022-2023 tour.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cla329m2k42md0806b9gjumeo-portraitImage-1667839928780.jpg',
  },
  {
    id: 'cle62gn96bj8n0822f3bb2mhe',
    name: 'Vision & Destiny',
    slug: 'vision-and-destiny',
    description:
      'Peterson draws upon his extensive research and relatable real-life experiences to illustrate how to develop attainable goals for intimate relationships, meaningful friendships, and your career. Transform the chaotic potential of the future into actuality — with a vision.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cle62gn96bj8n0822f3bb2mhe-portraitImage-1676489334575.jpg',
  },
  {
    id: 'clatxy5h55aab0866d3sx8dzv',
    name: 'Logos & Literacy',
    slug: 'logos-literacy',
    description: `Embark on an enlightening journey as Dr Jordan B Peterson leads us through the Museum of the Bible in this must-see feature-length documentary. Get ready to delve into the depths of the Bible's impact on society, as we explore its influence on our worldview and how it shapes our understanding of the world. Join Jordan as he meets with experts in history, theology, and philosophy to uncover the rich history of the Bible and its continued relevance in shaping our beliefs and values.`,
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clatxy5h55aab0866d3sx8dzv-portraitImage-1678294246679.jpg?628644',
  },
  {
    id: 'cm34s2mwq41zm08780lnso232',
    name: 'The Gospels',
    slug: 'the-gospels',
    description: `In the swarm of the chaos that seeks to devour the West, Dr. Jordan B. Peterson conducts his third biblical series as a last ditch effort to save the world from imminent destruction. Join him and eight of the most exceptional minds in the world as they unravel the mysteries of the Gospel story to resurrect the anchoring principles of the text that might save the West.`,
    portraitImage: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/JBP_Gospels_9x16_NewSeries.png',
  },
  {
    id: 'clashvhirqr6q0894uk2zo37f',
    name: 'Exodus',
    slug: 'exodus',
    description: `Dr. Jordan B. Peterson has captivated millions worldwide with his profound biblical lectures on Genesis. Now, Peterson has gathered a team of formidable scholars for an unprecedented, in-depth analysis of Exodus, an academic work to be taken seriously in the modern era.`,
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clashvhirqr6q0894uk2zo37f-portraitImage-1684262288460.jpg?7394.899999976158',
  },
];

export const PragerUShows = [
  {
    id: 'clerfvjza9bkd0938gqfs0ht4',
    name: 'Ami on the Loose',
    slug: 'ami-on-the-loose',
    description:
      'Documentary filmmaker Ami Horowitz travels the world asking questions to help people consider an alternative perspective.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clerfvjza9bkd0938gqfs0ht4-portraitImage-1677781654921.png',
  },
  {
    id: 'cl4ynmd1wmzxr0922fd1xhqoj',
    name: 'PragerU Master’s Program',
    slug: 'the-prager-u-master-s-program-with-dennis-prager',
    description:
      'Dennis Prager founded PragerU with a simple premise: give us five minutes and we’ll give you an education. Now one of the most influential conservative thinkers is going beyond the five-minute mark with this DailyWire+ exclusive. The world wants to make you woke. Let Dennis Prager make you wise.',
    portraitImage: '/images/shows/pu-masters-program-evergreen.jpg',
  },
  {
    id: 'cl89b69ag36bn0910shacd1vj',
    name: 'Real Talk with Marissa',
    slug: 'real-talk-with-marissa',
    description:
      'Marissa Streit, CEO of PragerU, has authentic and difficult conversations with some of the most knowledgeable and fascinating people in their fields.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cl84tekex4wjw0826navsd2h5-portraitImage-1664203540578.png',
  },
  {
    id: 'cli51qpb8g5wq0898i7uidmr0',
    name: 'The Wrap Up',
    slug: 'the-wrap-up-with-cj',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cli51qpb8g5wq0898i7uidmr0-portraitImage-1713460266475.jpg?234789.5',
    description: 'Recapping the biggest stories of the week you might have missed and definitely need to know.',
  },
  {
    id: 'cl5i8sgrc55gn0a98awtz6ca8',
    name: 'Unapologetic with Amala',
    slug: 'unapologetic-with-amala',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cl5i8sgrc55gn0a98awtz6ca8-portraitImage-1658444409333.png',
    description:
      'Unapologetic is a daily live show with monthly specials, exploring the biggest ideas and stories in culture, news, and politics with PragerU personality Amala Ekpunobi.',
  },
  {
    id: 'ckm1a4qu17rfy07989kqwjd11',
    name: 'Office Hours',
    slug: 'office-hours',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/ckm1a4qu17rfy07989kqwjd11-portraitImage-1616102577847.jpg',
    description:
      'Welcome to Office Hours, Daily Wire’s companion show to the famous PragerU “5-Minute Videos.” Every week, converted liberal and former teacher Georgia Howe hosts an in-depth discussion with a PragerU expert about their 5-minute video topic.',
  },
  {
    id: 'cls3nmxilgfo409262djpdhih',
    name: 'The Hustle',
    slug: 'the-hustle',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cls3nmxilgfo409262djpdhih-portraitImage-1706818454348.jpg',
    description: 'Stories of young entrepreneurs turning their passion into profit.',
  },
  {
    id: 'cl5u4aa2keiy00842yi7i9053',
    name: 'Around the World',
    slug: 'around-the-world',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cl5u4aa2keiy00842yi7i9053-portraitImage-1658451622397.png',
    description:
      'Meet children from around the world, hear their stories, and learn about global issues, such as socialism, communism, freedom of speech, and more. Discover why life is so exceptional in America compared to other countries.',
  },
  {
    id: 'cl9pty739418g0854vunskv5l',
    name: 'Cash Course',
    slug: 'cash-course',
    description:
      'PragerU Kids | 6th Grade & Up. A fun and educational show highlighting the importance of money management and financial literacy for youth to young adults.',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cl9ptwljf43nv07864kskzagp-portraitImage-1666800376731.png',
  },
  {
    id: 'cl5h685netmid0850xzpp97v0',
    name: 'Guess or Mess',
    slug: 'guess-or-mess',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/cl5h685netmid0850xzpp97v0-portraitImage-1658444091449.png',
    description:
      'Guess or Mess is a wild and fun quiz show that features trivia and total mayhem as kids compete to answer questions from our PragerU Kids magazines. Read the magazines and guess along at home (mess optional!)',
  },
  {
    id: 'clcp5tocyjzd70826nhmdfft2',
    name: 'Street Smarts',
    slug: 'street-smarts',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clcp5tocyjzd70826nhmdfft2-portraitImage-1673290274874.png',
    description:
      'U.S. civics and history lessons in a fun quiz show that will have your elementary students laughing while they learn.',
  },
  {
    id: 'clubc1szueihv0806fgdt8fm9',
    name: 'Unboxed, USA',
    slug: 'unboxed-usa',
    portraitImage:
      'https://daily-wire-production.imgix.net/shows/clubc1szueihv0806fgdt8fm9-portraitImage-1711985944735.jpg',
    description: 'U.S. states are unboxed in this hilarious quiz show celebrating our amazing country.',
  },
];

export const videoCalloutData = {
  amIRacist: {
    title: 'Am I Racist?',
    redirectUrl: '/videos/am-i-racist',
    description: `Experience the #1 documentary of the decade. Follow Matt Walsh's hilarious journey as he goes deep undercover to infiltrate and expose the weird world of diversity, equity, and inclusion. Now streaming exclusively on DailyWire+`,
    backgroundImage: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/16x9.png',
  },
  convictingAMurderer: {
    title: 'Convicting A Murderer',
    redirectUrl: '/show/convicting-a-murderer',
    description:
      "Convicting a Murderer unveils the shocking truth behind one of the most controversial criminal cases in recent history. In Making a Murderer, Steven Avery was portrayed as an innocent victim of corrupt law enforcement, but there's more to the story than what we were shown. Join Candace as she sets the record straight by exposing hidden evidence in the murder of Teresa Halbach.",
    backgroundImage: '/images/shows/cam-watch-now.jpg',
  },
  empireOfTerror: {
    title: 'An Empire of Terror',
    redirectUrl: '/show/apollo-11-what-we-saw',
    description:
      'To comprehend Putin’s motivations, we must examine the historical context that shaped his behaviors. Bill Whittle returns with season 3 of What We Saw: An Empire of Terror. Bill’s powerful storytelling brings you back in time to witness the reigns of Lenin and Stalin. This historic tale of terror unveils the chilling reality of fear and violence that gripped millions and killed millions more.',
    backgroundImage: '/images/shows/empire-of-terror.jpg',
  },
  jordanVisionDestiny: {
    title: 'Vision & Destiny',
    redirectUrl: '/show/vision-and-destiny',
    description:
      'Renowned psychologist Dr Jordan B Peterson has created a five-part series to help you find clarity and direction. Learn how to discover your passions, create a vision for your life, and make the right choices to bring it to fruition. With Dr Peterson’s guidance, you can take control of your life and unlock your true potential.',
    backgroundImage:
      'https://daily-wire-production.imgix.net/shows/cle62gn96bj8n0822f3bb2mhe-backgroundImage-1676489335424.jpg',
  },
  ladyBallers: {
    title: 'Lady Ballers',
    redirectUrl: '/videos/lady-ballers',
    description:
      'In a world where women’s sports is being trans-formed, The Daily Wire calls foul with the most triggering comedy of the year. A once-great coach is on a hilarious journey back to victory by reuniting his former high school championship basketball team, but this time, he’s challenging them to play like girls.',
    backgroundImage: '/images/version2/watch/movies/lady-ballers-team.jpg',
  },
  mastersProgram: {
    title: 'PragerU Master’s Program',
    redirectUrl: '/show/the-prager-u-master-s-program-with-dennis-prager',
    description:
      'Dennis Prager founded PragerU with a simple premise: give us five minutes and we’ll give you an education. Now one of the most influential conservative thinkers is going beyond the five-minute mark with this DailyWire+ exclusive. The world wants to make you woke. Let Dennis Prager make you wise.',
    backgroundImage:
      'https://daily-wire-production.imgix.net/episodes/cldc20kcijyu809109jo4kx70/cldc20kcijyu809109jo4kx70-1674674596118.jpeg',
  },
  greatestLieEverSold: {
    title: 'The Greatest Lie Ever Sold',
    redirectUrl: '/videos/the-greatest-lie-ever-sold',
    backgroundImage: '/images/the-greatest-lie-ever-sold.jpg',
    description: `In the wake of George Floyd’s death, the media concocted a narrative that justified a summer of worldwide riots and helped contribute to the rise of Black Lives Matter, who used the chaos to raise 90 million dollars. Candace Owens follows the money and discovers exactly the money was spent, where it did and didn’t go.`,
  },
  jordanPeterson: {
    title: 'Exodus',
    redirectUrl: '/show/exodus',
    backgroundImage: '/images/shows/jp-exodus-nologo.jpg',
    description: `Dr Jordan B Peterson—the most important public intellectual of our time—has captivated millions worldwide with his profound biblical lectures, shining a spotlight on the most important book in the history of mankind as an academic work to be taken seriously in the modern era. In the followup to his massively successful lectures on Genesis, Jordan is joined by formidable theological and literary scholars for an in-depth review on Exodus.`,
  },
  judged: {
    title: 'JUDGED by Matt Walsh',
    redirectUrl: '/show/judged-by-matt-walsh',
    backgroundImage: '/images/version2/watch/judged-banner.png',
    description:
      'All rise for the Honorable Judge Walsh, court is now in session. Watch Matt Walsh bring his iconic deadpan delivery of common sense to real litigants with actual petty grievances. New episodes streaming Tuesdays at 8PM ET, only on DailyWire+.',
  },
  myDinnerWithTrump: {
    title: 'My Dinner with Trump',
    redirectUrl: '/videos/my-dinner-with-trump',
    backgroundImage: '/images/dinner_with_trump.jpg',
    description: `This is the Trump that the media will never show you. Unguarded with his closest advisors. Access like no president has ever given. Love him or hate him, you’ve never seen anything like this before.`,
  },
  runHideFight: {
    title: 'Run Hide Fight',
    redirectUrl: '/videos/run-hide-fight',
    backgroundImage:
      'https://daily-wire-production.imgix.net/modulepage/images/cl4oez7e5nmpf0934obho0bxo-FREEFORM_HIGHLIGHT-1665685106456.jpg?w=300&h=250',
    description:
      'All Zoe Hull wants is to get through the last few weeks of her senior year and leave — off to college and a fresh start. Instead, her high school is attacked by four nihilistic, gun-toting students, who plan to make their siege the worst school shooting in history.',
  },
  theDividedStates: {
    title: 'The Divided States of Biden',
    redirectUrl: '/show/the-divided-states-of-biden',
    backgroundImage:
      'https://daily-wire-production.imgix.net/shows/clrtr4619pnjq0822kpue38yj-1710793750755.png?424717.8000000119',
    description:
      'Joe Biden is tearing America apart, and the media refuses to report on the impact of Biden’s treasonous policies. Join Ben Shapiro on the ground as he explores the real world consequences of one of the most destructive presidencies in American history.',
  },
  theSearchElon: {
    title: 'Ben Shapiro with Elon Musk',
    redirectUrl: '/episode/ben-shapiro-with-elon-musk',
    backgroundImage:
      'https://daily-wire-production.imgix.net/episodes/clrsedcm89zj30926lfm5bdpn/clrsedcm89zj30926lfm5bdpn-1706138461162.png?fit=crop&ar=16%3A9&w=1080&auto=format&ixlib=react-9.3.0',
    description:
      'Elon Musk joins Ben in Kraków for an exclusive conversation where they hit it all: DEI, SpaceX, aliens, the meaning of life, and how to hold on to the American dream.',
  },
  whatIsAWoman: {
    title: 'What is a Woman?',
    redirectUrl: 'https://www.dailywire.com/videos/what-is-a-woman',
    backgroundImage:
      'https://daily-wire-production.imgix.net/videos/cl3oygjszcf6c0d385m6h0usx/cl3oygjszcf6c0d385m6h0usx-1653686860363.jpg',
    description:
      'It’s the question you’re not allowed to ask. The documentary they don’t want you to see. Join Matt Walsh on his often comical, yet deeply disturbing journey, as he fearlessly questions the logic behind a gender ideology movement that has taken aim at women and children. Watch “What is a Woman?” now streaming exclusively for Daily Wire members.',
  },
  birchum: {
    title: 'Mr. Birchum',
    redirectUrl: '/show/mr-birchum',
    backgroundImage: '/images/version2/watch/banner_birchum.png',
    description:
      'Meet Mr. Birchum, a no-nonsense woodshop teacher at odds with his gamer son, snowflake students, and the school’s JEDI (Justice, Equity, Diversity, and Inclusion) officer, Karponzi. Starring Adam Carolla, Megyn Kelly, Roseanne Barr, and more. Exclusively on DailyWire+.',
  },
  soundOfHope: {
    title: 'Sound of Hope: The Story of Possum Trot',
    redirectUrl: '/videos/sound-of-hope-the-story-of-possum-trot-official-trailer-angel-studios',
    backgroundImage: '/images/version2/watch/sound-of-hope-watch-now.png',
    description:
      '“Sound of Hope: The Story of Possum Trot” is the true story of Donna and Reverend Martin and their tiny church in East Texas. 22 families adopted 77 children from the local foster system, igniting a movement for vulnerable children everywhere.',
  },
  scamala: {
    title: 'Scamala: Kamala Harris Unmasked',
    redirectUrl: '/show/scamala-kamala-harris-unmasked',
    backgroundImage: '/images/scamala/watch-now-banner.png',
    description:
      'Meet Kamala Harris, the incompetent and unqualified vice president. Ben Shapiro’s 3-part series exposes her façade, from her early California politics to the vice presidency. Uncover the scandals, incompetence, inappropriate laughter, and radicalism that define her. Scamala: Kamala Harris Unmasked reveals her dangerous ambition.',
  },
  election: {
    title: 'Election Wire',
    redirectUrl: '/show/election-wire',
    backgroundImage:
      'https://daily-wire-production.imgix.net/shows/cl9zzxux60fey0886nt8himsg-1721074737955.png?13978.59999999404',
    description: `The Daily Wire takes on the RNC! We're at the heart of the action, delivering on-location reports, interviews with key figures, and the latest political drama. Join us nightly during the convention at 7 PM ET for comprehensive coverage of the day's events and then a live stream of the Republican National Convention.`,
  },
  amIRacistTrailer: {
    title: '',
    redirectUrl: '/videos/am-i-racist-official-trailer',
    backgroundImage:
      'https://daily-wire-production.imgix.net/videos/clyz2e46ucwhp07829d5fofgm/clyz2e46ucwhp07829d5fofgm-1722883728521.jpg',
    description: `From the white guys who brought you "What Is A Woman?" comes their next great question, and America's next great movie, "Am I Racist?" Matt Walsh goes undercover in the world of DEI, hilariously skewering the absurdity of race hustlers. Prepare to be shocked by how far the grifters will go and how much further Matt Walsh will go to expose them. In theaters Sept 13th, tickets on sale Aug 15th.`,
  },
  fallOfMinneapolis: {
    title: 'The Fall of Minneapolis',
    redirectUrl: '/videos/the-fall-of-minneapolis',
    backgroundImage: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/FallofMinneapolis_16x9B.png',
    description: `"The Fall of Minneapolis" exposes the city's decline under Governor Tim Walz's leadership. Now that Harris taps Walz as her running mate, this documentary becomes crucial viewing. It reveals the true events behind Minneapolis's struggles, raising questions about potential national leadership. Stream free now on DailyWire+`,
  },
  foundationsOfTheWest: {
    title: 'Foundations of the West',
    redirectUrl: '/show/foundations-of-the-west',
    backgroundImage:
      'https://daily-wire-production.imgix.net/shows/clzu59x6ffwhi0894fm90ajnv-1723659657283.jpg?2647659',
    description: `Journey through the ancient cities that shaped the West. Discover the impact these civilizations had on our modern world. In this 5 part series, Dr. Jordan B. Peterson, accompanied by his esteemed colleagues, traverse iconic sites and unpack the profound impact these societies have had on shaping the western world. Through insightful discussions and up close examination of key sites that hosted historical accounts in man's story, Dr. Peterson and special guests are able to dissect the legacies these nations have made in standing the test of time.`,
  },
};

export const HeroCalloutData = [
  {
    key: 1,
    isEpisode: true,
    buttonText: 'Watch Exodus',
    extendedContentUrl: 'https://www.dailywire.com/show/exodus',
    previewImageUrl: 'https://daily-wire-production.imgix.net/shows/clashvhirqr6q0894uk2zo37f-1669138365505.jpg',
  },
  {
    key: 2,
    extendedContentUrl: 'https://www.jeremysrazors.com/',
    previewImageUrl: '/images/shave-off.jpg',
    buttonText: 'Shut Up and Shave',
  },
  {
    key: 3,
    extendedContentUrl: 'https://www.dailywire.com/videos/what-is-a-woman',
    previewImageUrl:
      'https://daily-wire-production.imgix.net/clips/cl3pxyyu7p52x0806wcrhtgfr/WIAW_Trailer_Thumbnail.jpg',
    trailerSlug: 'what-is-a-woman-official-trailer',
  },
];

export const masteringLifeSeriesJBP = [
  {
    id: 'cl4pos0tr0bic09509png5hdg',
    name: 'Masculinity',
    coverImage: 'https://dailywireplus-v2.imgix.net/uploads/jbp-website-1-masculinity.png',
    slug: 'dragons-monsters-and-men',
    weight: 89,
    description:
      'Discover your purpose and slay the dragons that stand in your way. Watch the new 4-part series on men, masculinity, and the pursuit of greatness.',
  },
  {
    id: 'cl9ohiteh5qj70794t3usr4hq',
    name: 'Marriage',
    coverImage: 'https://dailywireplus-v2.imgix.net/uploads/jbp-website-2-marriage.png',
    slug: 'dr-jordan-b-peterson-on-marriage',
    weight: 89,
    description:
      'This holistic 3-part series prepares you to better understand, appreciate, and strengthen your relationship with your spouse, and achieve the ultimate goal: the perfect date that repeats endlessly.',
  },
  {
    id: 'cle62gn96bj8n0822f3bb2mhe',
    name: 'Vision & Destiny',
    coverImage: 'https://dailywireplus-v2.imgix.net/uploads/jbp-website-3-vision-destiny.png',
    slug: 'vision-and-destiny',
    description:
      'Peterson draws upon his extensive research and relatable real-life experiences to illustrate how to develop attainable goals for intimate relationships, meaningful friendships, and your career. Transform the chaotic potential of the future into actuality — with a vision.',
  },
  {
    id: 'cm114za4nmi0b09544zkyklis',
    name: 'Depression & Anxiety',
    coverImage: 'https://dailywireplus-v2.imgix.net/uploads/jbp-website-4-depression.png',
    slug: 'dr-jordan-b-peterson-on-depression-anxiety',
    description:
      'Dr. Jordan B. Peterson reveals his revolutionary approach to conquering depression, anxiety, and resentment in this groundbreaking five-part series. See powerful psychotherapy techniques from his clinical work, uncover the roots of mental anguish, and arm yourself with tools to reclaim your life.',
  },
  {
    id: 'cm18cldz0o8v30878y14y8jq0',
    name: 'Negotiation',
    coverImage: 'https://dailywireplus-v2.imgix.net/uploads/jbp-website-5-negotiation.png',
    slug: 'negotiation',
    weight: 75,
    description:
      'Master the art of negotiation with Dr. Jordan B. Peterson and transform your life. Learn to navigate personal and professional challenges, creating win-win scenarios in every interaction. Part of the "Mastering Life" catalog, this series reveals how mastering negotiation leads to a richer, more fulfilling existence.',
  },
  {
    id: 'cm18cldz0o8v30878y14y8jq1',
    name: 'Success',
    coverImage: 'https://dailywireplus-v2.imgix.net/uploads/jbp-website-6-success.png',
    slug: 'success',
    description: `If you're in the throws of disappointment despite outward achievement, your lens for success might be broken. In his newest series from the Mastering Life collection, Dr. Peterson redefines conventional notions of success, offering a profound reevaluation of what it means to live a fulfilling life.`,
  },
];
