import React from 'react';

import styles from './Homepage.module.css';

export const GiftGuide: React.FC = () => {
  const items = [
    {
      id: 1,
      link: 'https://get.dailywire.com/gift-guide/?_gl=1*1ieqdz8*_gcl_au*MjA5MDU5NjgyMi4xNzI2NjA4OTY3LjEyMTQ4OTgyNDkuMTczMDg2ODcxOS4xNzMwODY4NzQ4*_ga*MTM0NzEwODcxNC4xNzAzMTA5MTkx*_ga_CZKR9ZYKJP*MTczMzMyODU0Ni42MDMuMS4xNzMzMzI4NTU1LjUxLjAuMA',
      image: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/DW_BlackFriday_GiftGuide_593x339.png',
      alt: 'Dailywire Gift Guide',
    },
    {
      id: 2,
      link: 'https://www.jeremysrazors.com/collections/black-friday-24/products/precision-5-1-year-bundle-us?utm_campaign=Gen2&utm_medium=cpg&utm_source=dw-site&utm_content=dailywire_CyberWeek',
      image: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/1yearJR292x163_CMAS1.jpg',
      alt: `Jeremy's Razors`,
    },
    {
      id: 3,
      link: 'https://mayflowercigars.com/mayflower-8-cigar-assortment/?utm_campaign=mknewproduct&utm_medium=web&utm_source=dw-site&utm_content=dailywire&utm_term=CyberWeek',
      image: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/mayflower_new.jpg',
      alt: 'Mayflower Cigars',
    },
    {
      id: 4,
      link: 'https://thecandleclub.com/collections/holiday-favorites/products/knowles-seasonal-candle?selling_plan=6350766386?utm_campaign=christmas&utm_medium=web&utm_source=dw-site&utm_content=dailywire&utm_term=CyberWeek',
      image: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/WISEMAN_292x163CMAS1.jpg',
      alt: 'The Candle Club',
    },
    {
      id: 5,
      link: 'https://store.dailywire.com/products/truffle-tumbler-2024?_pos=1&_sid=8b177a9ca&_ss=r?utm_campaign=christmas&utm_medium=web&utm_source=dw-site&utm_content=dailywire&utm_term=CyberWeek',
      image: 'https://dailywireplus-v2.imgix.net/images/dailywire.com/YN3PACK_292x163CMAS1.jpg',
      alt: 'Leftist Tears',
    },
  ];

  return (
    <div className={styles.giftGuide}>
      {items.map((item) => {
        return (
          <a className={styles.giftItem} href={item.link} key={item.id} rel='noreferrer' target='_blank'>
            <img alt={item.alt} className={styles.giftImage} src={item.image} />
          </a>
        );
      })}
    </div>
  );
};
