import React from 'react';

import Head from 'next/head';

// eslint-disable-next-line import/no-unresolved
import { APP_STORE_ID, SITE_DESCRIPTION, SITE_NAME, SITE_URL, OG_IMAGE_DEFAULT } from '@/lib/constants';

export default function DefaultSEO() {
  return (
    <Head>
      <title>{SITE_NAME}</title>
      <link href={SITE_URL} rel='canonical' />
      <meta content={`app-id=${APP_STORE_ID}`} name='apple-itunes-app' />
      <meta content='en_US' property='og:locale' />
      <meta content={SITE_NAME} property='og:site_name' />
      <meta content='website' property='og:type' />
      <meta content={SITE_DESCRIPTION} name='description' />
      <meta content={SITE_NAME} property='og:title' />
      <meta content={SITE_DESCRIPTION} property='og:description' />
      <meta
        content='https://dailywireplus-v2.imgix.net/images/dailywire.com/DW-Talent-Panel-2025.jpg'
        property='og:image'
      />
      <meta content={SITE_URL} property='og:url' />
      <meta content='@realDailyWire' name='twitter:site' />
      <meta content={SITE_NAME} property='twitter:title' />
      <meta content={SITE_DESCRIPTION} property='twitter:description' />
      <meta content='summary_large_image' name='twitter:card' />
      <meta content={OG_IMAGE_DEFAULT} name='twitter:image' />
    </Head>
  );
}
