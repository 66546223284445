/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { Global, CacheProvider } from '@emotion/core';
// Use only { cache } from 'emotion'. Don't use { css }.
import { cache } from 'emotion';
import { ThemeProvider } from 'emotion-theming';
import { initializeApp } from 'firebase/app';
import { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';

import { UIProvider } from '@/components/design-system/context';
import { Layout as SiteLayout } from '@/components/layout/Layout';
import { Auth0Provider } from '@/context/auth';
import useLocalStorage from '@/hooks/useLocalStorage';
import { clearABTestData, getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

import { SITE_URL } from '../lib/constants';
import CookieScrubber from '../lib/cookieScrubber';
import { theme, globalStyles } from '../theme';

import '@/styles/watch.css';
import '@fontsource/lato';
import '@fontsource/lato/400-italic.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/700-italic.css';
import '@fontsource/lato/900.css';
import '../fonts/beaufort/stylesheet.css';
import 'react-toastify/dist/ReactToastify.css';
import '@/styles/tokens.css';
import '@/styles/globals.css';
import '@/styles/utils.css';
import { LightModeToggleProvider } from '@/context/LightModeToggleContext';
import { getGlobalBannerData } from '@/request/global-banner';

// Import the functions you need from the SDKs you need

const onRedirectCallback = (appState: any) => {
  Router.replace(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

Router.events.on('routeChangeComplete', () => {
  if ((window as any)?.wisepops) {
    (window as any)?.wisepops('pageview');
  }
});

export default function App({ Component, pageProps }: AppProps) {
  const [xData, setXData] = useLocalStorage('x_data', { hasAccount: false, twclid: null });
  const Layout = (Component as any).Layout || SiteLayout;
  const { query } = useRouter();
  const { globalBannerData, overrideDefaultPage } = pageProps;

  if (!process.env.NEXT_PUBLIC_AUTH0_AUDIENCE) {
    throw new Error(`Enviorment variable NEXT_PUBLIC_DW_CHAT_API_HOST is missing`);
  }

  if (!process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID) {
    throw new Error(`Enviorment variable NEXT_PUBLIC_AUTH0_CLIENT_ID is missing`);
  }

  if (!process.env.NEXT_PUBLIC_AUTH0_DOMAIN) {
    throw new Error(`Enviorment variable NEXT_PUBLIC_AUTH0_DOMAIN is missing`);
  }

  React.useEffect(
    function () {
      if (query.twclid && query.twclid !== xData.twclid) {
        const { effectivePlan = 'FREE' } = JSON.parse(localStorage.getItem('ajs_user_traits'));
        let hasAccount = xData.hasAccount;

        if (!xData.hasAccount && effectivePlan !== 'FREE') {
          hasAccount = true;
        }

        setXData({
          hasAccount,
          twclid: query.twclid,
        });
      }
    },
    [setXData, query, xData],
  );

  React.useEffect(() => {
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional

    const firebaseConfig =
      window.location.hostname !== 'www.dailywire.com'
        ? {
            apiKey: 'AIzaSyAw3JQX5vrKo-J-H6rAl3KXGUvl5V3-MG4',
            authDomain: 'qna-data.firebaseapp.com',
            databaseURL: 'https://qna-data-default-rtdb.firebaseio.com',
            projectId: 'qna-data',
            storageBucket: 'qna-data.appspot.com',
            messagingSenderId: '776681347968',
            appId: '1:776681347968:web:f1b44904a06c7b4f5aff65',
            measurementId: 'G-CLZF056ZLL',
          }
        : {
            apiKey: 'AIzaSyDz8eMoBJZb_mOuNFl7M7BZ9pvAv30ReXA',
            authDomain: 'the-daily-wire.firebaseapp.com',
            databaseURL: 'https://the-daily-wire.firebaseio.com',
            projectId: 'the-daily-wire',
            storageBucket: 'the-daily-wire.appspot.com',
            messagingSenderId: '430922604431',
            appId: '1:430922604431:web:55066512571d657fcff5fa',
            measurementId: 'G-75ES7BWGYQ',
          };

    // Initialize Firebase
    initializeApp(firebaseConfig);
  }, []);

  React.useEffect(() => {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();
    if ((window as any)?.wisepops) {
      // Wisepop Viewed
      (window as any)?.wisepops('listen', 'before-popup-display', function (event) {
        window.__DW_Next_Bridge?.Analytics.logEvent(`Popup Viewed`, {
          ...abTestData,
          ...utmCookieData,
          campaign_id: event.detail.popup.id,
        });
      });
      // Wisepop Clicked
      (window as any)?.wisepops('listen', 'before-tracked-click', function (event) {
        window.__DW_Next_Bridge?.Analytics.logEvent(`Popup Clicked`, {
          ...abTestData,
          ...utmCookieData,
          campaign_id: event.detail.popup.id,
        });
      });
    }
  }, []);

  React.useEffect(() => {
    const changeClasses = () => {
      // setValue('value')

      if (localStorage && localStorage.getItem('ajs_user_traits')) {
        const userTraits = JSON.parse(localStorage.getItem('ajs_user_traits'));

        let handledLoading = false;

        if (userTraits?.loginsCount > 0) {
          document.getElementsByTagName('Html')[0].classList.add('lts-authenticated');
        } else {
          document.getElementsByTagName('Html')[0].classList.add('lts-anonymous');
        }
        if (userTraits?.effectivePlan == 'FREE') {
          document.getElementsByTagName('Html')[0].classList.add('lts-member-free');
          handledLoading = true;
        }
        if (userTraits?.effectivePlan == 'READER') {
          document.getElementsByTagName('Html')[0].classList.add('lts-member-read-only');
          handledLoading = true;
        }
        if (userTraits?.effectivePlan == 'INSIDER') {
          document.getElementsByTagName('Html')[0].classList.add('lts-member-insider-monthly');
          handledLoading = true;
        }
        if (userTraits?.effectivePlan == 'INSIDER_PLUS') {
          document.getElementsByTagName('Html')[0].classList.add('lts-insider-annual');
          handledLoading = true;
        }
        if (userTraits?.effectivePlan == 'ALL_ACCESS') {
          document.getElementsByTagName('Html')[0].classList.add('lts-all-access');
          handledLoading = true;
        }

        if (handledLoading) {
          setTimeout(function () {
            document.getElementsByTagName('Html')[0].classList.remove('lts-loading');
          }, 1500);
        }
      }
    };
    changeClasses();
  });

  React.useEffect(() => {
    const interval = setInterval(CookieScrubber, 5000);

    Router.events.on('routeChangeComplete', () => {
      setTimeout(CookieScrubber, 300);
    });

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    clearABTestData();
  }, []);
  React.useEffect(() => {
    //set initial value for ads (video unit specific)
    (window as any).showAds = true;
  }, []);

  React.useEffect(() => {
    const receiveMessage = (event) => {
      if (
        ['https://e.ddhq.io', 'https://embeds.ddhq.io'].includes(event.origin) &&
        event.data.id &&
        event.data.height
      ) {
        const iframe = document.getElementById(event.data.id);
        if (iframe) {
          iframe.style.height = `${event.data.height}px`;
        }
      }
    };

    window.addEventListener('message', receiveMessage, false);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('message', receiveMessage);
  }, []);

  return (
    <>
      <Auth0Provider
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
        client_id={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
        // @ts-ignore
        onRedirectCallback={onRedirectCallback}
        redirect_uri={`${SITE_URL}/callback`}
      >
        <LightModeToggleProvider>
          <ThemeProvider theme={theme}>
            <CacheProvider value={cache}>
              <Global styles={globalStyles} />
              <UIProvider>
                <Layout globalBannerData={globalBannerData} overrideDefaultPage={overrideDefaultPage}>
                  <Component {...pageProps} />
                </Layout>
              </UIProvider>
            </CacheProvider>
          </ThemeProvider>
        </LightModeToggleProvider>
      </Auth0Provider>
      <Script src='https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js' strategy='beforeInteractive' />
    </>
  );
}

App.getInitialProps = async (appContext) => {
  // Fetch the global banner data
  // TODO: THIS IS A FEATURE FLAG MORE OR LESS. PROBABLY LESS.
  // const globalBannerData = await getGlobalBannerData();
  const globalBannerData = null;
  const pageProps = appContext.Component.getInitialProps
    ? await appContext.Component.getInitialProps(appContext.ctx)
    : {};

  return {
    pageProps: {
      ...pageProps,
      globalBannerData,
    },
  };
};
