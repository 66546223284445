export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL
  ? process.env.NEXT_PUBLIC_SITE_URL
  : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;

export const SITE_NAME = 'The Daily Wire - Breaking News, Videos & Podcasts';
export const SITE_DESCRIPTION =
  'Get daily coverage of the latest news and important stories in politics, culture, education, and sports at dailywire.com.';
export const OG_IMAGE_DEFAULT = 'https://dailywireplus-v2.imgix.net/images/dailywire.com/DW-Talent-Panel-2025.jpg';

// Content
export const PRAGERU_HOME_UTM_URL = 'https://www.prageru.com/?utm_source=dailywire&utm_medium=blog&utm_campaign=brand';
export const PRAGERU_DONATE_URL = 'https://donate.prageru.com/DW';
export const ALL_ACCESS_LIVE_SHOW_SLUG = 'all-access-live';
export const BOOK_CLUB_SHOW_SLUG = 'ben-shapiros-book-club';
export const SHOT_DARK_ASSETS_URL = 'https://public.dailywire.com/e/assets/shows/shotinthedark';
export const SHOT_DARK_SHOW_SLUG = 'a-shot-in-the-dark';

// Zendesk
export const ZENDESK_KEY = 'ee838f8c-f737-40d6-86bc-6aae0cacd334';

// Formspree
export const FORMSPREE_PROJECT_ID = '1565290110662999173';

// Apollo
export const APOLLO_CLIENT_NAME = 'DW_WEBSITE';

// Mux
export const MUX_STREAM_URL = 'stream.media.dailywire.com';

// Mobile Apps
export const APP_STORE_ID = '1477317200';
export const APP_NAME = 'The Daily Wire';
export const GOOGLE_PLAY_ID = 'com.dailywire.thedailywire';
export const MOBILE_APP_GOOGLE_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.dailywire.thedailywire&hl=en_IN&gl=US';
export const MOBILE_APP_APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/the-daily-wire/id1477317200';
export const GET_THE_APP_URL = `${SITE_URL}/app`;
export const FIREBASE_DYNAMIC_LINK_URL = process.env.NEXT_PUBLIC_FIREBASE_DYNAMIC_LINK_URL;

//Live Chat
export const CHAT_API_URL = process.env.NEXT_PUBLIC_DW_CHAT_API_URL ?? 'https://app.dailywire.com/__/v2/chat';
export const LIVE_CHAT_MAX_CHAT_MESSAGE_LENGTH = 500;
export const LIVE_CHAT_MAX_MESSAGES = 500;
export const LIVE_CHAT_POLLINGINTERVALMS = 2000;

// Watch Time
export const WATCH_TIME_RESET_SECONDS = 10;

//GTAG
export const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID;

export const MIDDLEWARE_URL = process.env.NEXT_PUBLIC_MIDDLEWARE_URL ?? 'https://middleware-prod.dailywire.com';
