/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';

import SectionHeader from '../sectionHeader';

import { GiftGuide } from '@/components/homepage/GiftGuide';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { saleBannerIsEnabled } from '@/utils/helpers/blackFriday';

import styles from './allPosts.module.css';
import Post from './post';

export default function AllPosts({ posts, children }) {
  const { isHD, isDesktop, isTablet, isPhablet } = useBreakpoints();
  const [colSpan, setColSpan] = React.useState('span 1');
  /* This is used to determine where the advertisement
  component should be depending on screen size and how many
  items should be above it (should be below two rows always)*/

  function calculateIndex(index: number) {
    if (isHD || isDesktop) {
      return index === 5;
    } else {
      return index === 3;
    }
  }
  /* This is used to determine how wide the advertisement
  component should be depending on screen size */
  React.useEffect(() => {
    if (isHD) {
      setColSpan('span 4');
    } else if (isDesktop) {
      setColSpan('span 3');
    } else if (isTablet || isPhablet) {
      setColSpan('span 2');
    } else {
      setColSpan('span 1');
    }
  }, [isHD, isDesktop, isTablet, isPhablet]);
  return (
    <div className={styles.section}>
      <SectionHeader title='Latest News' />
      <div className={styles.posts}>
        {posts?.map((post, index: number) => {
          const uniqueKey = `${post.id}-${index}`; // Ensure uniqueness with id and index
          return (
            <React.Fragment key={uniqueKey}>
              <Post data={post} index={index} />
              {saleBannerIsEnabled() && calculateIndex(index) && (
                <div
                  style={{
                    gridColumn: colSpan,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <GiftGuide />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      {children}
    </div>
  );
}
