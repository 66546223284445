import * as React from 'react';

type LiveBlogComponentProps = {
  eventId: string;
};

const LiveBlogComponent: React.FC<LiveBlogComponentProps> = ({ eventId }) => {
  React.useEffect(() => {
    if (!eventId) return;
    const script = document.createElement('script');
    script.src = `https://go.arena.im/public/js/arenalib.js?p=daily-wire&e=${eventId}`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [eventId]);

  if (!eventId) return null;

  return <div className='arena-liveblog' data-event={eventId} data-publisher='daily-wire' data-version='2' />;
};

export default LiveBlogComponent;
