/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';
import { useState, useEffect } from 'react';

import axios from 'axios';
import NextLink from 'next/link';

import styles from './ElectionArticles.module.css';

import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

interface Article {
  id: number;
  title: {
    rendered: string;
  };
  slug: string;
  link: string;
  date: string;
  _embedded: {
    author: Array<{
      id: number;
      name: string;
    }>;
    'wp:featuredmedia': Array<{
      source_url: string;
    }>;
  };
}

const ElectionArticles = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  //Donald trump related articles
  const apiUrl = 'https://news.dailywire.com/wp-json/wp/v2/posts?tags=11&per_page=6&_embed=1';

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
      });
  }, []);

  const formatDate = (dateString: string): string => {
    const articleDate = new Date(dateString);
    const currentDate = new Date();
    const diffInHours = Math.floor((currentDate.getTime() - articleDate.getTime()) / (1000 * 60 * 60));
    const diffInMinutes = Math.floor((currentDate.getTime() - articleDate.getTime()) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `less than 1 hour ago`;
    } else if (diffInHours === 1) {
      return `${diffInHours} hour ago`;
    } else if (diffInHours <= 48) {
      return `${diffInHours} hours ago`;
    } else {
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
      };
      return articleDate.toLocaleDateString(undefined, options);
    }
  };

  const decodeHtmlEntities = (text: string) => {
    if (typeof document === 'undefined') return text;

    const txt = document.createElement('textarea');
    txt.innerHTML = text;
    return txt.value;
  };

  const handleSegmentClick = () => {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
      ...abTestData,
      ...utmCookieData,
      label: 'View All',
      location: 'Election Home Page',
      destination: 'https://www.dailywire.com/read',
    });
  };

  return (
    <div className={styles.ElectionNightContainer}>
      <div className={styles.ElectionNightInnerContainer}>
        <h3 className={styles.ElectionNightHeader}>
          Inauguration <span> News</span>
        </h3>
      </div>
      <div className={styles.ElectionNightGrid}>
        {/* First Article */}
        <NextLink href={`/news/${articles[0]?.slug}`}>
          <a className={`${styles.ElectionNightArticleLink} ${styles.articleSpan3}`}>
            <img
              alt={articles[0]?.title.rendered}
              className={styles.ElectionNightArticleImage}
              src={`${articles[0]?._embedded['wp:featuredmedia'][0].source_url}?ar=16%3A6&fit=crop&crop=faces&w=3840&auto=format&ixlib=react-9.3.0)`}
            />
            <div className={styles.ElectionNightArticleContent}>
              <p className={styles.ElectionNightArticleText}>{decodeHtmlEntities(articles[0]?.title.rendered)}</p>
              <div className={styles.ElectionNightFlexRow}>
                <p className={styles.ElectionNightTextStyle}>
                  By {articles[0]?._embedded.author[0].name} | {formatDate(articles[0]?.date)}
                </p>
              </div>
            </div>
          </a>
        </NextLink>

        {/* Second Article */}
        {articles.length > 1 && (
          <NextLink href={`/news/${articles[1]?.slug}`}>
            <a className={`${styles.ElectionNightGridContainer} ${styles.articleSpan1}`}>
              <img
                alt={articles[1].title.rendered}
                className={styles.ElectionNightImage}
                src={`${articles[1]._embedded['wp:featuredmedia'][0].source_url}?ar=16%3A6&fit=crop&crop=faces&w=3840&auto=format&ixlib=react-9.3.0)`}
              />
              <div className={styles.ElectionNightFlexColumn}>
                <p className={styles.ElectionNightTextParagraph}>{decodeHtmlEntities(articles[1].title.rendered)}</p>
                <div className={styles.ElectionNightFlexRowCenter}>
                  <p className={styles.ElectionNightTextStyle}>
                    By {articles[1]._embedded.author[0].name} | {formatDate(articles[1].date)}
                  </p>
                </div>
              </div>
            </a>
          </NextLink>
        )}

        {/* Articles 2-5 mobile */}
        <div className={styles.ElectionNightScrollContainer}>
          {articles.slice(1).map((article) => (
            <div key={article.id}>
              <div style={{ margin: '0.75rem 0', backgroundColor: '#B8B8B8', height: '1px' }} />
              <div className={styles.ElectionNightFlexItem}>
                <NextLink href={`/news/${article?.slug}`}>
                  <a className={styles.ElectionNightFlexColumnFull}>
                    <div className={styles.ElectionNightFlexGrowColumn}>
                      <p className={styles.ElectionNightTextParagraph}>{decodeHtmlEntities(article.title.rendered)}</p>
                      <p className={styles.ElectionNightTextStyle}>
                        By {article._embedded.author[0].name} | {formatDate(article.date)}
                      </p>
                    </div>
                    <img
                      alt={article.title.rendered}
                      className={styles.ElectionNightImageStyle}
                      src={`${article._embedded['wp:featuredmedia'][0].source_url}?ar=16%3A6&fit=crop&crop=faces&w=3840&auto=format&ixlib=react-9.3.0)`}
                    />
                  </a>
                </NextLink>
              </div>
            </div>
          ))}
          <div style={{ margin: '0.75rem 0', backgroundColor: '#B8B8B8', height: '1px' }} />
        </div>

        {/* Articles 3-5 */}
        <div className={styles.ElectionNightArticlesThreeContainer}>
          {articles.slice(2).map((article) => (
            <NextLink href={`/news/${article?.slug}`}>
              <a className={styles.ElectionNightFlexColumnFullWhite} href={`${article.link}`} key={article.id}>
                <img
                  alt={article.title.rendered}
                  className={styles.ElectionNightImageStyleTopRounded}
                  src={`${article._embedded['wp:featuredmedia'][0].source_url}?ar=16%3A6&fit=crop&crop=faces&w=3840&auto=format)`}
                />
                <div className={styles.ElectionNightFlexGrowColumnPadding}>
                  <h3 className={styles.ElectionNightTextEllipsis}>{decodeHtmlEntities(article.title.rendered)}</h3>
                  <p className={styles.ElectionNightTextStyleAutoMargin}>
                    By {article._embedded.author[0].name} | {formatDate(article.date)}
                  </p>
                </div>
              </a>
            </NextLink>
          ))}
        </div>
      </div>
      <div
        className={styles.viewAllContainer}
        onClick={() => {
          handleSegmentClick();
        }}
      >
        <NextLink href='/read'>
          <p className={styles.viewAllLink}>View All</p>
        </NextLink>
      </div>
    </div>
  );
};

export default ElectionArticles;
