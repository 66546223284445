import React, { FC } from 'react';

export enum SkeletonLoaderType {
  episode = 'episode',
}

type SkeletonLoaderProps = {
  count: number;
  type: SkeletonLoaderType;
};

export const SkeletonLoader: FC<SkeletonLoaderProps> = ({ count, type }) => {
  switch (type) {
    case SkeletonLoaderType.episode:
      return <EpisodeSkeletonLoader count={count} />;
    default:
      return null;
  }
};

type EpisodeSkeletonLoaderProps = {
  count: number;
};

const linearGradient = 'linear-gradient(180deg, #151515 0%, #1B1B1B 50%, #151515 100%)';

const EpisodeSkeletonLoader: FC<EpisodeSkeletonLoaderProps> = ({ count }) => {
  const renderComponents = (count: number) => {
    return Array.from({ length: count }, (_, i) => (
      <div>
        <div
          key={i}
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          }}
        >
          <div
            className='pulse-animation'
            css={(theme) => ({ borderRadius: '15px', [theme.mq.desktop]: { borderRadius: '15px' } })}
            style={{
              gridColumn: '1/2',
              aspectRatio: '16/9',
              background: linearGradient,
              alignSelf: 'start',
            }}
          />
          <div
            className='pulse-animation'
            css={(theme) => ({
              marginTop: '0',
              gridTemplateRows: '1fr 1.8fr ',
              [theme.mq.desktop]: { marginTop: '7px', gridTemplateRows: '0.7fr 0.4fr 2fr' },
            })}
            style={{
              display: 'grid',
              rowGap: '8px',
              gridColumn: '2/4',
              marginLeft: '1rem',
            }}
          >
            <div
              style={{
                background: linearGradient,
                gridRow: '1/2',
                height: '22px',
                borderRadius: '3px',
              }}
            />
            <div
              style={{
                background: linearGradient,
                gridRow: '2/3',
                height: '14px',
                borderRadius: '3px',
              }}
            />
            <div
              css={(theme) => ({ display: 'none', [theme.mq.desktop]: { display: 'flex' } })}
              style={{
                gridRow: '3/4',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <div style={{ background: linearGradient, height: '18px', borderRadius: '3px' }} />
              <div style={{ background: linearGradient, height: '18px', borderRadius: '3px' }} />
              <div style={{ background: linearGradient, height: '18px', borderRadius: '3px' }} />
            </div>
          </div>
        </div>
        <div
          css={(theme) => ({ display: 'flex', [theme.mq.desktop]: { display: 'none' } })}
          style={{
            gridRow: '3/4',
            flexDirection: 'column',
            gap: '8px',
            marginTop: '12px',
          }}
        >
          <div style={{ background: linearGradient, height: '16px', borderRadius: '3px' }} />
          <div style={{ background: linearGradient, height: '16px', borderRadius: '3px' }} />
          <div style={{ background: linearGradient, height: '16px', borderRadius: '3px' }} />
        </div>
      </div>
    ));
  };
  return (
    <div style={{ width: '100%', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: '32px' }}>
      {renderComponents(count)}
    </div>
  );
};
