/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import NextLink from 'next/link';

import { debounce } from '@/utils/helpers';

import { LoadingComponent } from '@/components/design-system';

import s from './Homepage.module.css';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

const CONTENT_PAD = 2;
const MOBILE_SLIDE_WIDTH_REDUCTION = 40;
const TABLET_BREAKPOINT = 750;

enum IndicatorState {
  current = 'current',
  empty = 'empty',
  full = 'full',
}

type CTA = {
  link: string;
  text: string;
};

type ItemImage = {
  url: string;
};

export type Item = {
  image: ItemImage;
  logo?: ItemImage;
  mainCTA: CTA;
  mobileImage: ItemImage;
  position?: number;
  secondaryCTA?: CTA;
  tag?: string;
  text?: string;
};

interface HpCarouselProps {
  email: string;
  featuredContent: any[];
  slideDuration?: number;
  transitionDuration?: number;
}

interface HpIndicatorsProps {
  activeIndex: number;
  callback: any;
  content: any[];
  contentPad: number;
  paused: boolean;
}

interface HpIndicatorProps {
  onClick: any;
  state: IndicatorState;
  isActive: boolean;
}

interface SlideItemProps {
  email: string;
  item: Item;
  handleTouchMove: (e: any) => void;
  handleTouchStart: (e: any) => void;
  width: number;
}

const HomepageCarousel: React.FC<HpCarouselProps> = function ({
  email = null,
  featuredContent,
  slideDuration = 5000,
  transitionDuration = 400,
}) {
  const touchStartPosition = useRef(0);
  const carouselRef = useRef(null);
  const timerIds = useRef([]);
  const slideTrackRef = useRef(null);
  const slideWidth = useRef(0);
  const windowWidth = useRef(window.innerWidth);
  const [activeIndex, setActiveIndex] = useState(CONTENT_PAD - 1);
  const [isPaused, setIsPaused] = useState(false);
  const [actionTaken, setActionTaken] = useState(false);
  const [loading, setLoading] = useState(true);
  const content = useRef(null);
  if (!content.current) {
    content.current = createContentArray(featuredContent, CONTENT_PAD);
  }

  const gotoSlide = useCallback(
    function (slideIndex) {
      const { length } = content.current;

      if (slideIndex > length - CONTENT_PAD) {
        slideIndex = CONTENT_PAD;
      } else if (slideIndex < 1) {
        slideIndex = length - CONTENT_PAD - 1;
      }

      const mobileOffset = calculateMobileOffsetDifference(TABLET_BREAKPOINT, MOBILE_SLIDE_WIDTH_REDUCTION);

      setActiveIndex(slideIndex);
      if (slideTrackRef.current) {
        slideTrackRef.current.style.transitionDuration = transitionDuration + 'ms';
        slideTrackRef.current.style.left = -slideIndex * slideWidth.current + mobileOffset + 'px';
      }

      if (slideIndex === length - CONTENT_PAD) {
        return setTimeout(function () {
          if (slideTrackRef.current) {
            slideTrackRef.current.style.transitionDuration = '0ms';
            slideTrackRef.current.style.left = slideWidth.current * -CONTENT_PAD + mobileOffset + 'px';
          }

          setActiveIndex(CONTENT_PAD);
        }, transitionDuration);
      }

      if (slideIndex === CONTENT_PAD - 1) {
        const wrapIndex = length - CONTENT_PAD - 1;

        return setTimeout(function () {
          if (slideTrackRef.current) {
            slideTrackRef.current.style.transitionDuration = '0ms';
            slideTrackRef.current.style.left = -wrapIndex * slideWidth.current + mobileOffset + 'px';
          }

          setActiveIndex(wrapIndex);
        }, transitionDuration);
      }
    },
    [content, transitionDuration],
  );

  const nextSlide = useCallback(
    function () {
      gotoSlide(activeIndex + 1);
    },
    [activeIndex, gotoSlide],
  );

  const previousSlide = useCallback(
    function () {
      gotoSlide(activeIndex - 1);
    },
    [activeIndex, gotoSlide],
  );

  const handleMouseEnter: MouseEventHandler = function () {
    if (content.current.length <= 1) return;
    clearTimeouts(timerIds.current);
    setIsPaused(true);
  };

  const handleMouseLeave: MouseEventHandler = function () {
    if (content.current.length <= 1) return;
    timerIds.current.push(setTimeout(nextSlide, slideDuration));
    setIsPaused(false);
  };

  const handleTouchMove = useCallback(
    (event) => {
      if (actionTaken) return;
      const touch = event.changedTouches[0].clientX;
      const el = slideTrackRef.current;
      if (touch < touchStartPosition.current - 20) {
        el.removeEventListener('touchend', handleTouchMove);
        clearTimeouts(timerIds.current);
        nextSlide();
        setActionTaken(true);
      } else if (touch > touchStartPosition.current + 20) {
        el.removeEventListener('touchend', handleTouchMove);
        clearTimeouts(timerIds.current);
        previousSlide();
        setActionTaken(true);
      }
    },
    [actionTaken, nextSlide, previousSlide],
  );

  const handleTouchStart = useCallback(
    (event) => {
      touchStartPosition.current = event.changedTouches[0].clientX;
      setActionTaken(false);
      const el = slideTrackRef.current;
      if (el) {
        el.addEventListener('touchend', handleTouchMove);
      }
    },
    [handleTouchMove],
  );

  useEffect(function () {
    const width = calculateSlideWidth(carouselRef.current, TABLET_BREAKPOINT, MOBILE_SLIDE_WIDTH_REDUCTION);

    slideWidth.current = width;
    if (slideTrackRef.current) {
      slideTrackRef.current.style.left =
        -width * CONTENT_PAD + calculateMobileOffsetDifference(TABLET_BREAKPOINT, MOBILE_SLIDE_WIDTH_REDUCTION) + 'px';
    }
    setActiveIndex(CONTENT_PAD);
  }, []);

  useEffect(
    function () {
      const handleWindowResize = debounce(function () {
        const resizeWidth = window.innerWidth;
        if (carouselRef.current.clientWidth === slideWidth.current || resizeWidth == windowWidth.current) {
          setIsPaused(false);
          return;
        }
        const width = calculateSlideWidth(carouselRef.current, TABLET_BREAKPOINT, MOBILE_SLIDE_WIDTH_REDUCTION);

        clearTimeouts(timerIds.current);
        slideWidth.current = width;
        if (slideTrackRef.current) {
          slideTrackRef.current.style.transitionDuration = '0ms';
          slideTrackRef.current.style.left =
            -activeIndex * width +
            calculateMobileOffsetDifference(TABLET_BREAKPOINT, MOBILE_SLIDE_WIDTH_REDUCTION) +
            'px';
        }
        windowWidth.current = window.innerWidth;
        timerIds.current.push(
          setTimeout(function () {
            setIsPaused(false);
          }, 500),
          setTimeout(function () {
            setIsPaused(false);
            nextSlide();
          }, slideDuration),
        );
      }, 250);

      function resizeStart() {
        const resizeWidth = window.innerWidth;
        if (resizeWidth == windowWidth.current) return;
        if (isPaused === false) {
          setIsPaused(true);
        }
      }

      window.addEventListener('resize', resizeStart);
      window.addEventListener('resize', handleWindowResize);
      return function () {
        window.removeEventListener('resize', resizeStart);
        window.removeEventListener('resize', handleWindowResize);
      };
    },
    [activeIndex, isPaused, nextSlide, slideDuration],
  );

  useEffect(
    function () {
      if (content.current.length <= 1) return;
      if (isPaused) return;

      clearTimeouts(timerIds.current);
      timerIds.current.push(setTimeout(nextSlide, slideDuration));

      return function () {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeouts(timerIds.current);
      };
    },
    [isPaused, nextSlide, slideDuration, featuredContent],
  );

  React.useEffect(() => {
    if (slideWidth.current && slideWidth.current !== 0) {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [loading]);

  if (!content.current) {
    return null;
  }

  return (
    <div
      className={cn(s.carouselContainer)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={carouselRef}
      style={
        {
          '--slide-duration': `${slideDuration}ms`,
          '--transition-duration': `${transitionDuration}ms`,
          // '--background-image': `url(${item?.image?.url})`,
        } as React.CSSProperties
      }
    >
      {loading ? <LoadingComponent /> : undefined}
      <div
        className={s.slideTrack}
        ref={slideTrackRef}
        style={{
          width: slideWidth.current * content.current.length + 'px',
        }}
      >
        {content.current.map(function (item, index) {
          return (
            <SlideItem
              email={email}
              handleTouchMove={handleTouchMove}
              handleTouchStart={handleTouchStart}
              item={item}
              // eslint-disable-next-line react/no-array-index-key
              key={`${item?.title}-${index}`}
              width={slideWidth.current}
            />
          );
        })}
      </div>
      {content.current.length > 1 && (
        <>
          <div className={`${s.chevronContainer} ${s.left}`} onClick={previousSlide}>
            <ChevronLeft />
          </div>
          <div className={`${s.chevronContainer} ${s.right}`} onClick={nextSlide}>
            <ChevronRight />
          </div>
          <Indicators
            activeIndex={activeIndex}
            callback={gotoSlide}
            content={featuredContent}
            contentPad={CONTENT_PAD}
            paused={isPaused}
          />
        </>
      )}
    </div>
  );
};

const SlideItem: React.FC<SlideItemProps> = function ({ email, item, handleTouchMove, handleTouchStart, width }) {
  function handleClick(event, callback) {
    event.stopPropagation();
    callback();
  }
  function imageCTAClick() {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      ...utmCookieData,
      column: item?.position,
      destination: item?.mainCTA?.link,
      email: email,
      label: '',
      location: 'Carousel Image CTA',
      referrer: '/',
      rowHeadline: item?.tag || '',
    });
  }
  function mainCTAClick() {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      ...utmCookieData,
      column: item?.position,
      destination: item?.mainCTA?.link,
      email: email,
      label: item?.mainCTA?.text,
      location: 'Carousel Main CTA',
      referrer: '/',
      rowHeadline: item?.tag || '',
    });
  }

  function secondaryCTAClick() {
    if (!item?.secondaryCTA) return;

    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      ...utmCookieData,
      column: item?.position,
      destination: item?.secondaryCTA?.link,
      email: email,
      label: item?.secondaryCTA?.text,
      location: 'Carousel Secondary CTA',
      referrer: '/',
      rowHeadline: item?.tag || '',
    });
  }
  return (
    <div className={s.slideItem} style={{ width: width + 'px' }}>
      <NextLink href={item?.mainCTA?.link ?? '/'}>
        <div
          className={s.imgContainer}
          onClick={(e) => handleClick(e, imageCTAClick)}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
        >
          {item?.tag && <div className={s.tag}>{item?.tag}</div>}
          <img alt={item?.text} className={s.imgDesktop} src={item?.image?.url} />
        </div>
      </NextLink>
      {item?.logo && (
        <div className={s.slideLogo}>
          <img alt={item?.text} src={item?.logo?.url} />
        </div>
      )}
      <div className={s.containerThing}>
        <h2 className={s.slideText}>{item?.text}</h2>
        <div className={s.slideCTA}>
          <NextLink href={item?.mainCTA?.link ?? '/'}>
            <a className={s.watchNow} onClick={(e) => handleClick(e, mainCTAClick)}>
              <PlayIcon />
              {item?.mainCTA?.text}
            </a>
          </NextLink>
          {item?.secondaryCTA && (
            <NextLink href={item?.secondaryCTA?.link ?? '/'}>
              <a className={s.episodes} onClick={(e) => handleClick(e, secondaryCTAClick)}>
                {item?.secondaryCTA?.text}
              </a>
            </NextLink>
          )}
        </div>
      </div>
    </div>
  );
};

const Indicators: React.FC<HpIndicatorsProps> = function ({ activeIndex, callback, content, contentPad, paused }) {
  const indicators = content.map(function (c, i) {
    const index = justifyIndex(activeIndex - contentPad, content.length);
    let state = IndicatorState.empty;
    let isActive = false;
    if (i === index) {
      state = IndicatorState.current;
      isActive = true;
      if (paused) {
        state = IndicatorState.full;
      }
    }

    return (
      <Indicator
        isActive={isActive}
        key={c?.image?.url}
        onClick={function () {
          callback(i + contentPad);
        }}
        state={state}
      />
    );
  });

  return (
    <div className={s.indicatorsContainer}>
      <div className={s.indicators}>{indicators}</div>
    </div>
  );
};

const Indicator: React.FC<HpIndicatorProps> = function ({ onClick, state, isActive }) {
  return (
    <div className={s.indicatorOuter} onClick={onClick}>
      <div className={isActive == true ? `${s.indicator} ${s.isActive}` : s.indicator}>
        <div
          className={cn(s.indicatorInner, {
            [s.indicatorCurrent]: state === 'current',
            [s.indicatorEmpty]: state === 'empty',
            [s.indicatorFull]: state === 'full',
          })}
        />
      </div>
    </div>
  );
};

const ChevronLeft: React.FC = function () {
  return (
    <div className={cn(s.chevron, s.chevronLeft)}>
      <svg fill='none' height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'>
        <path
          clipRule='evenodd'
          d='M19.4129 23.1629C19.0468 23.529 18.4532 23.529 18.0871 23.1629L10.5871 15.6629C10.221 15.2968 10.221 14.7032 10.5871 14.3371L18.0871 6.83709C18.4532 6.47097 19.0468 6.47097 19.4129 6.83709C19.779 7.2032 19.779 7.7968 19.4129 8.16291L12.5758 15L19.4129 21.8371C19.779 22.2032 19.779 22.7968 19.4129 23.1629Z'
          fill='white'
          fillRule='evenodd'
        />
      </svg>
      <span className='sr-only'>Previous</span>
    </div>
  );
};

const ChevronRight: React.FC = function () {
  return (
    <div className={cn(s.chevron, s.chevronRight)}>
      <svg fill='none' height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'>
        <path
          clipRule='evenodd'
          d='M10.5871 6.83709C10.9532 6.47097 11.5468 6.47097 11.9129 6.83709L19.4129 14.3371C19.779 14.7032 19.779 15.2968 19.4129 15.6629L11.9129 23.1629C11.5468 23.529 10.9532 23.529 10.5871 23.1629C10.221 22.7968 10.221 22.2032 10.5871 21.8371L17.4242 15L10.5871 8.16291C10.221 7.7968 10.221 7.2032 10.5871 6.83709Z'
          fill='white'
          fillRule='evenodd'
        />
      </svg>
      <span className='sr-only'>Next</span>
    </div>
  );
};

const PlayIcon: React.FC = function () {
  return (
    <svg
      className={s.playIcon}
      fill='none'
      height='26'
      viewBox='0 0 26 26'
      width='26'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 12.718C25 19.2349 19.6274 24.518 13 24.518C6.37258 24.518 1 19.2349 1 12.718C1 6.20101 6.37258 0.917969 13 0.917969C19.6274 0.917969 25 6.20101 25 12.718Z'
        fill='white'
      />
      <path d='M10 8.29141L17 12.7164L10 17.1414V8.29141Z' fill='#050505' />
      <path
        d='M25 12.718C25 19.2349 19.6274 24.518 13 24.518C6.37258 24.518 1 19.2349 1 12.718C1 6.20101 6.37258 0.917969 13 0.917969C19.6274 0.917969 25 6.20101 25 12.718Z'
        stroke='#050505'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 8.29141L17 12.7164L10 17.1414V8.29141Z'
        stroke='#050505'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

function calculateMobileOffsetDifference(breakpoint: number, mobileOffset: number): number {
  if (window.innerWidth < breakpoint) {
    return mobileOffset / 2;
  }

  return 0;
}

function calculateSlideWidth(container: Element, breakpoint: number, difference: number): number {
  if (!container) return 0;

  if (window.innerWidth < breakpoint) {
    return container.clientWidth - difference;
  }

  return container.clientWidth;
}

function clearTimeouts(ids: number[]) {
  ids.forEach(function (id) {
    clearTimeout(id);
  });

  ids.splice(0);
}

function createContentArray(originalContent: any[], pad: number): any[] {
  if (!originalContent?.length) {
    console.warn('Original content array must have at least one item for HomepageCarousel');
    return [];
  }
  if (originalContent.length === 1) {
    return originalContent;
  }

  if (originalContent.length < pad) {
    console.warn(`Content array should have at least ${pad} items for best results`);
    return new Array(5).fill(originalContent[0]);
  }
  return [...originalContent.slice(-pad), ...originalContent, ...originalContent.slice(0, pad)];
}

function justifyIndex(index: number, length: number): number {
  if (index < 0) {
    return length - 1;
  }
  if (index >= length) {
    return 0;
  }

  return index;
}

export function liveEpisodeToCarouselItem(episode): Item {
  const showSlug = episode.show.slug;

  const item: Item = {
    image: { url: episode?.image ?? `/images/home-carousel/${showSlug}/desktop.jpg?v=2` },
    // logo: { url: '`/images/home-carousel/${showSlug}/logo.png?v=2`' },
    logo: null,
    mainCTA: {
      link: `/episode/${episode.slug}`,
      text: 'Watch Now',
    },
    mobileImage: { url: `/images/home-carousel/${showSlug}/mobile.jpg?v=2` },
    secondaryCTA: {
      link: `/show/${showSlug}`,
      text: 'View Show',
    },
    tag: 'LIVE',
    text: episode?.title,
  };

  return item;
}

export default HomepageCarousel;
