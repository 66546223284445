var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"FS7RduONQO4b7y6XUOaYu"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as Sentry from '@sentry/nextjs';

import { processEnvAsBoolean, processEnvAsInt } from './sentry.helpers';

enum SentryLogLevel {
  LOG = 'LOG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  ASSERT = 'ASSERT',
}

const SENTRY_LOG_LEVELS: string[] = [SentryLogLevel.ERROR.toLowerCase()];

const sentryClientOptions: Sentry.BrowserOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

  tracesSampleRate: processEnvAsInt(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE, 1.0),

  debug: processEnvAsBoolean(process.env.NEXT_PUBLIC_SENTRY_DEBUG, false),

  replaysOnErrorSampleRate: processEnvAsInt(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, 1.0),

  replaysSessionSampleRate: processEnvAsInt(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE, 0.1),

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.captureConsoleIntegration({
      levels: SENTRY_LOG_LEVELS,
    }),
  ],
};

Sentry.init(sentryClientOptions);
