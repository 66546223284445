export const blackFridayCode = 'DW50';
export const blackFridayUpgradeCode = 'dw50upgrade';
export const blackFridayUpgradeMonthlyCode = 'upgrade50';
export const saleCode = 'BALLERS';

export function blackFridaySaleIsEnabled(): boolean {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('_devBlackFriday') !== null) {
    return true;
  } else {
    const currentDate = new Date().getTime();
    const startDate = new Date('2024-11-22T00:00:00').getTime();
    const endDate = new Date('2024-12-26T23:59:00').getTime();
    return currentDate >= startDate && currentDate < endDate;
  }
}

export function saleIsEnabled(): boolean {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('_devRunningDiscount') !== null) {
    return true;
  } else {
    const currentDate = new Date().getTime();
    const startDate = new Date('2024-11-09T00:00:00').getTime();
    const endDate = new Date('2024-11-25T23:59:00').getTime();
    return currentDate >= startDate && currentDate < endDate;
  }
}

export function saleBannerIsEnabled(): boolean {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('_devRunningDiscount') !== null) {
    return true;
  } else {
    const currentDate = new Date().getTime();
    const startDate = new Date('2024-12-09T00:00:00').getTime();
    const endDate = new Date('2025-01-20T23:59:00').getTime();
    return currentDate >= startDate && currentDate < endDate;
  }
}

function createBannerIsEnabled(startDateStr, endDateStr) {
  return function () {
    if (typeof localStorage !== 'undefined' && localStorage.getItem('_devRunningDiscount') !== null) {
      return true;
    } else {
      const currentDate = new Date().getTime();
      const startDate = new Date(startDateStr).getTime();
      const endDate = new Date(endDateStr).getTime();
      return currentDate >= startDate && currentDate < endDate;
    }
  };
}

export const fortSevenBanner = createBannerIsEnabled('2024-12-09T00:00:00', '2025-01-26T22:00:00');
