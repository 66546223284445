/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';

import { GetStaticProps } from 'next';
import DefaultSEO from '@/components/seo/default';
import { Container } from '@/components/design-system';
import HomepageBreakingNews from '@/components/homepage/HomepageBreakingNews';
import { liveEpisodeToCarouselItem } from '@/components/homepage/HomepageCarousel';
import HomepageHero from '@/components/homepage/HomepageHero';
import Main from '@/components/shared/Main';
import Carousel from '@/components/ui/Carousel-v2';
import AllPosts from '@/components/homepage/allPosts/allposts';
import { useAuth0 } from '@/context/auth';
import { appApi, Episode } from '@/graphql/app';
import { chatApi } from '@/graphql/chat';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { ALL_ACCESS_LIVE_SHOW_SLUG } from '@/lib/constants';
import { filterMemberExclusiveEpisodes } from '@/lib/memberExclusive';
import { getUtmCookieData } from '@/lib/utm_cookie';
import {
  getLeadPosts,
  getFeaturedPosts,
  getAuthorWithPosts,
  getCoverPosts,
  getRecentPosts,
  getTrendingTopics,
} from '@/lib/wordpress';
import { usePosts } from '@/request/post';
import { LoadMoreButton } from '@/utils/styles';
import { WpPost } from 'types/wp';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { getPageOverride } from '@/lib/pageOverrides';
import HomepageElectionDay from '@/components/homepage/HomepageElectionDay';
import { useLightModeToggle } from '@/context/LightModeToggleContext';

interface HomePageProps {
  latestEpisodes: Episode[];
  leadPosts: WpPost[];
  featuredPosts: WpPost[];
  recentPosts: WpPost[];
  episodeData: any;
  overrideDefaultPage: boolean;
}
// the * 3 is because we are loading 3 pages worth of items up front
const RECENT_POSTS_PAGE_SIZE = 16 * 3;

const HomePage: React.FC<HomePageProps> = ({ episodeData, overrideDefaultPage, ...props }) => {
  // const [, setAbTestData] = useState<any>(getABTestData());
  const { setIsLightModeActive } = useLightModeToggle();

  useEffect(() => {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
      title: 'The Daily Wire',
      abSessionId: abTestData.abSessionId,
      abTestName: abTestData.abTestName,
      abTestGroup: abTestData.abTestGroup,
      abTestFeature: abTestData.abTestFeature,
      ...utmCookieData,
    });

    // setAbTestData(abTestData);
  }, []);

  overrideDefaultPage ? setIsLightModeActive(true) : setIsLightModeActive(false);
  return overrideDefaultPage ? <HomepageElectionDay episodeData={episodeData} /> : <HomePageWithCarousel {...props} />;
};

interface HomePageWithCarouselProps {
  latestEpisodes: Episode[];
  leadPosts: WpPost[];
  featuredPosts: WpPost[];
  recentPosts: WpPost[];
}

const HomePageWithCarousel: React.FC<HomePageWithCarouselProps> = ({
  leadPosts,
  featuredPosts,
  recentPosts,
  latestEpisodes,
}) => {
  const { isHD, isDesktop, isPhablet, isMobile } = useBreakpoints();
  let pageSize = isHD ? 14 : 16;
  pageSize = isDesktop ? 12 : pageSize;
  const [breakingNews, setBreakingNews] = useState(null);
  const [featuredContent, setFeaturedContent] = useState([]);
  const [contentLoading, setContentLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState('#F7F7F7');
  const { data, size, setSize, isValidating } = usePosts([], [recentPosts]);
  const allRecentPosts = data?.map((page) => page?.map((post) => post)).flat();
  const atEnd = !data?.[data?.length - 1]?.length;
  const { isAuthenticated, subscriber } = useAuth0();

  const latestEpisodesFiltered = filterMemberExclusiveEpisodes(latestEpisodes, isAuthenticated, subscriber);

  function nearestMultiple(n: number, m: number): number {
    return Math.floor(n / m) * m;
  }

  useEffect(function () {
    async function getHomeHeroData(breakingNewsOnly = false, intervalId) {
      const carouselFeatureFlag = localStorage.getItem('carouselFeatureFlag');
      const url = `${process.env.NEXT_PUBLIC_BENTKEY_ENV_PATH}/__/v1/dw/content/feed`;

      try {
        const resp = await fetch(url);
        if (!resp.ok) {
          throw new Error('Failed to fetch home hero data');
        }
        const data = await resp.json();
        let breakingNews = null;
        let heroCarousel = null;
        if (carouselFeatureFlag) {
          breakingNews = data?._V2?.dw?.breakingNews;
          heroCarousel = data?._V2?.dw?.heroCarousel;
        } else {
          breakingNews = data?.breakingNews;
          heroCarousel = data?.heroCarousel;
        }

        if (breakingNews !== null && breakingNews.length) {
          const liveBreakingNews = breakingNews.filter(function ({ isLive }) {
            return isLive;
          });

          setBreakingNews(liveBreakingNews[0] || null);
        }
        if (breakingNewsOnly) return;
        if (heroCarousel !== null && heroCarousel.length) {
          const liveEpisodeData = await appApi.getLiveEpisodes();
          const liveEpisodes = liveEpisodeData.getLiveEpisodes
            .filter(function ({ isLive, status }) {
              return isLive && status.trim().toLowerCase() == 'live';
            })
            .map(liveEpisodeToCarouselItem);
          const allItems = [...liveEpisodes, ...heroCarousel].map(function (item, index) {
            item.position = index;
            return item;
          });
          setFeaturedContent(allItems);
        }
        setContentLoading(false);
      } catch (err) {
        clearInterval(intervalId);
        setContentLoading(false);
      }
    }

    getHomeHeroData(false, null);

    const intervalId = setInterval(function () {
      getHomeHeroData(true, intervalId);
    }, 60000);

    return function () {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setBackgroundColor('#F7F7F7');
    } else {
      setBackgroundColor('#F7F7F7');
    }
  }, [isMobile, isPhablet]);

  return (
    <Main bg={backgroundColor} style={{ paddingBottom: 40 }}>
      <DefaultSEO />
      <HomepageBreakingNews email={subscriber?.email || null} news={breakingNews} />
      {featuredContent && featuredContent.length ? (
        <HomepageHero
          email={subscriber?.email || null}
          featuredContent={featuredContent}
          featuredPosts={[leadPosts[0], ...featuredPosts]}
          loading={contentLoading}
          news={breakingNews}
        />
      ) : (
        <HomepageHero
          email={subscriber?.email || null}
          featuredContent={null}
          featuredPosts={[leadPosts[0], ...featuredPosts]}
          loading={contentLoading}
          news={null}
        />
      )}
      <Carousel
        backgroundColor='#0A0909'
        episodes={latestEpisodesFiltered}
        href='/watch'
        includePadding={true}
        title='Latest Episodes'
      />
      <Container>
        {/* because we have varying items in each row depending on screen size,
          we need to ensure that the number of items is a multiple of the page size
          so that the last row is filled. We base this off the closest (lower) multiple 
          of the target size -> pageSize */}
        <AllPosts posts={allRecentPosts.slice(0, nearestMultiple(allRecentPosts.length, pageSize))}>
          {!atEnd && (
            <LoadMoreButton mode='light' onClick={() => setSize(size + 1)}>
              {isValidating ? 'Loading...' : `More stories`}
            </LoadMoreButton>
          )}
        </AllPosts>
      </Container>
    </Main>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  // const pageOverride = await getPageOverride('inauguration_day');
  const pageOverride = null;
  if (pageOverride && pageOverride.isActive && pageOverride.episode) {
    const episodeData = await appApi.getEpisodeBySlug({ slug: pageOverride.episode.slug });

    return {
      props: {
        episodeData: episodeData,
        overrideDefaultPage: true,
      },
      revalidate: 300, // 5 mins (in seconds)
    };
  }

  let discussions: any;

  const [
    leadPosts,
    featuredPosts,
    latestEpisodesData,
    allAccessLiveEpisodesData,
    openDiscussionsData,
    upcomingDiscussionsData,
    endedDiscussionsData,
  ] = await Promise.all([
    getLeadPosts(1),
    getFeaturedPosts(4),
    appApi.getEpisodes({ first: 12 }),
    appApi.getEpisodesByShow({ first: 12, showSlug: ALL_ACCESS_LIVE_SHOW_SLUG }),
    chatApi.getDiscussions({ first: 10, status: ['OPEN'] }),
    chatApi.getDiscussions({ first: 10, status: ['UPCOMING'] }),
    chatApi.getDiscussions({ first: 2, status: ['ENDED'] }),
  ]);

  const exclusionIDs = [];
  if (leadPosts && leadPosts.length) {
    exclusionIDs.push(leadPosts[0].id);
  }
  if (featuredPosts) {
    featuredPosts.forEach((post) => exclusionIDs.push(post.id));
  }

  const recentPosts = await getRecentPosts(RECENT_POSTS_PAGE_SIZE, 0, exclusionIDs);

  // NOTE: Currently stuck with this no-so-efficent 3-query due to API limitations
  if (openDiscussionsData && openDiscussionsData.discussions) {
    // side step destructive nature of array.reverse()
    const openReversed = [...openDiscussionsData.discussions].reverse();
    discussions = [...openReversed];
  }
  if (upcomingDiscussionsData && upcomingDiscussionsData.discussions) {
    // side step destructive nature of array.reverse()
    const upcomingReversed = [...upcomingDiscussionsData.discussions].reverse();
    discussions = [...discussions, ...upcomingReversed];
  }
  if (endedDiscussionsData && endedDiscussionsData.discussions) {
    discussions = [...discussions, ...endedDiscussionsData.discussions];
  }

  // Filtering out All Access Live
  const latestEpisodesFiltered = latestEpisodesData.episodes.filter(
    (episode) => episode?.show.slug !== ALL_ACCESS_LIVE_SHOW_SLUG,
  );

  // Step: 1 - Parallel fetching of all content *except* the readers pass carousel
  const [coverPosts, benShapiroPosts, candaceOwensPosts, jordanPetersonPosts, trendingTopics, parselyTopTopicsData] =
    await Promise.all([
      getCoverPosts(1),
      getAuthorWithPosts('ben-shapiro', 16),
      getAuthorWithPosts('candace-owens', 16),
      getAuthorWithPosts('jordan-peterson', 16),
      getTrendingTopics(4),
      appApi.getParselyTopTopics({ limit: 3 }),
    ]);
  // Step: 2 - postIds to exclude from cover, ben, candace, and morning wire posts
  const postIdsToExclude = [
    ...(coverPosts ?? []),
    ...(benShapiroPosts.posts ?? []),
    ...(candaceOwensPosts.posts ?? []),
    ...(jordanPetersonPosts.posts ?? []),
  ].map((p) => p.id);

  // Step: 3 - the 16 latest readers pass posts, excluding step 2 ids
  const readersPassPosts = await getRecentPosts(6, 0, postIdsToExclude, { members_only: 'true' });
  const slug = context.params?.slug as string;
  const episodeData = await appApi.getEpisodeBySlug({ slug: 'ep-2060-member-exclusive' });

  if (!episodeData?.episode || slug == 'terror-on-the-prairie-world-premiere') {
    return {
      props: {
        episodeData: episodeData,
      },
      notFound: true,
      revalidate: 600,
    };
  }
  return {
    props: {
      episodeData: episodeData,
      leadPosts: leadPosts,
      featuredPosts: featuredPosts,
      recentPosts: recentPosts,
      latestEpisodes: latestEpisodesFiltered,
      allAccessLiveEpisodes: allAccessLiveEpisodesData.episodes,
      discussions: discussions,
      coverPosts: coverPosts ?? [],
      readersPassPosts: readersPassPosts ?? [],
      benShapiroPosts: benShapiroPosts.posts ?? [],
      candaceOwensPosts: candaceOwensPosts.posts ?? [],
      jordanPetersonPosts: jordanPetersonPosts.posts ?? [],
      trendingTopics: trendingTopics ?? [],
      parselyTopTopics: parselyTopTopicsData.topics ?? [],
      overrideDefaultPage: false,
    },
    revalidate: 600, // 10 mins (in seconds)
  };
};

export default HomePage;
