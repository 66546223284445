/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { memo, UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import style from './customStyle.module.css';
import { Message } from './Message';
import { MessageForm } from './MessageForm';
import { ScrollToBottomButton } from './ScrollToBottomButton';
import { useChatMessages } from './useChatMessages';
import { isScrolledToTheBottom } from './utils';
import { Loader } from '@/components/design-system';

export type LiveChatProps = {
  canLiveChat: boolean;
  roomId: string;
  roomStatus: string;
  updateRoomStatus?: (status: string) => void;
  isElection?: boolean;
};

export const LiveChat = memo<LiveChatProps>(
  ({ canLiveChat = true, roomId, isElection, roomStatus, updateRoomStatus }) => {
    const [firstLoad, setFirstLoad] = useState(true);
    // const [repliesShownMessageId, setRepliesShownMessageId] = useState<number>(undefined);
    const [showScrollToBottomButton, setShowScrollToBottomButton] = useState(false);

    const scrollableContainerElRef = useRef<HTMLDivElement>();
    const wasScrolledToTheBottom = useRef(false);
    const prevScrollValue = useRef(0);
    const loadingComponentHeight = 100;

    const { messages, tailMessageId, fetchTopMessages, loadingTopMessages, sendMessage, resetMessages, fetchMessages } =
      useChatMessages(roomId, {
        onFirstLoad() {
          if (firstLoad === true) {
            setTimeout(() => {
              setFirstLoad(false);
            }, 1000);
          }
        },

        onMessageSent: () => {
          scrollToBottom('smooth', null);
        },

        // onReplySent() {
        //   // FIXME: This is a quick and dirty way of doing this,
        //   // a proper implementation would probably need to keep
        //   // a reply form ref around
        //   document.querySelector('[data-element-name="reply-form"]')?.scrollIntoView({ block: 'nearest' });
        // },

        onSendMessageError(response) {
          if (response.status === 405) {
            updateRoomStatus('close');
          }
        },
      });

    const shouldShowScrollToBottomButton = useCallback(
      (container) => {
        const scrolledToTheBottom = isScrolledToTheBottom(container, 100);
        if (scrolledToTheBottom && showScrollToBottomButton) {
          setShowScrollToBottomButton(false);
        }

        wasScrolledToTheBottom.current = scrolledToTheBottom;
      },
      [showScrollToBottomButton],
    );

    const scrollToBottom = useCallback(
      (behavior, callback) => {
        const container = scrollableContainerElRef.current;
        if (!container) return;
        container.scrollTo({
          top: container.scrollHeight,
          behavior: behavior,
        });
        shouldShowScrollToBottomButton(container);
        /* This timeout is to ensure the smooth scrolling 
      completes before resetting the chats in the UI.
      600 is (supposed to be) the amount of time a given
      browser takes to complete a smooth scroll */
        if (callback && typeof callback === 'function') {
          setTimeout(() => {
            callback();
          }, 600);
        }
      },
      [shouldShowScrollToBottomButton],
    );

    // Scroll function
    const handleScroll = (e: UIEvent<HTMLDivElement>) => {
      const container = scrollableContainerElRef.current;

      shouldShowScrollToBottomButton(container);

      // Load new messages when scrolled to the top and ONLY when scrolling in the upward direction
      if (container?.scrollTop <= 1 && !firstLoad && container?.scrollTop < prevScrollValue.current) {
        // Prevent default scroll behavior
        e.preventDefault();

        /* When we fetch top messages and we load them into the state
`     the container jumps to the top and creates a weird UI experience
      below is the logic to store the height and ensure we scroll to
      the proper location in the container when top messages are fetched */

        // Save the current scroll height and scroll position before fetching messages
        const currentScrollHeight = container.scrollHeight;
        const currentScrollPosition = container.scrollTop;
        //fetch the top messages and then perform the scroll adjustment
        fetchTopMessages(messages)
          .then(() => {
            const newScrollHeight = container.scrollHeight;
            const heightDifference = newScrollHeight - currentScrollHeight;
            // Adjust the scroll position by the height difference to maintain the user's scroll position
            // 100 is the value that was set as the loading components height
            // this is to fix a safari bug where the scroll loading creates an empty view
            container.style.overflow = 'hidden';
            container.scrollTop = currentScrollPosition + heightDifference - loadingComponentHeight;
          })
          .finally(() => {
            // unset the scroll lock
            container.style.overflow = 'auto';
          });
      }

      /* Save the current scroll value for comparison in the next 
    scroll event. Really only used for top message fetching */
      prevScrollValue.current = container?.scrollTop;
    };

    const handleScrollToBottomClick = () => {
      reloadChat('auto');
      setShowScrollToBottomButton(false);
    };

    const reloadChat = useCallback(
      (behavior: string) => {
        scrollToBottom(behavior, resetMessages);
        wasScrolledToTheBottom.current = true;
      },
      [resetMessages, scrollToBottom],
    );

    // const handleMessageRepliesToggle = useCallback((messageId: number, active?: boolean) => {
    //   if (active) {
    //     return setRepliesShownMessageId(messageId);
    //   }

    //   // Note: We're assuming that if `active: false` is passed
    //   // then no matter what `messageId` value is, we just set the replies-shown
    //   // message ID to undefined. This covers all cases and doesn't make
    //   // us need a `repliesShownMessageId` value for comparison here
    //   // which would in turn cause `handleMessageRepliesToggle` to update
    //   // that in turn would cause every message component to rerender.
    //   // If we ever need to support multiple comment's replies open, then
    //   // this should be reconsidered anyway.
    //   setRepliesShownMessageId(undefined);
    // }, []);

    const handleMessageFormSubmit = useCallback(
      (message?: string) => {
        if (!canLiveChat) return;
        sendMessage(message), [sendMessage];
      },
      [canLiveChat, sendMessage],
    );

    // const handleReplyFormSubmit = useCallback(
    //   (originalMessageId?: number, message?: string) => {
    //     if (!canLiveChat) return;
    //     sendMessage(message, true, originalMessageId);
    //   },
    //   [canLiveChat, sendMessage],
    // );

    useEffect(() => {
      if (firstLoad || wasScrolledToTheBottom.current) {
        return reloadChat('smooth');
      }

      setShowScrollToBottomButton(true);
    }, [firstLoad, reloadChat, tailMessageId]);

    useEffect(() => {
      const handleFocus = () => {
        fetchMessages(roomId);
      };
      // Add the event listener for resetting chat when returning
      window.addEventListener('focus', handleFocus);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('focus', handleFocus);
      };
    }, [reloadChat, fetchMessages, messages, roomId]);

    const isRoomOpen = roomStatus === 'open';
    const loadingBgColor = isElection ? '#fff' : '#151515';

    return (
      <section
        className={`${isElection ? style.liveChatSectionElection : style.liveChatSection}`}
        style={{
          filter: canLiveChat ? 'none' : 'blur(4px)',
          WebkitFilter: canLiveChat ? 'none' : 'blur(4px)',
        }}
      >
        <div className={style.liveChatMainWrapper}>
          {firstLoad && (
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                background: loadingBgColor,
                zIndex: 1,
              }}
            >
              <Loader />
            </div>
          )}
          <div className={style.liveChatMain} onScroll={handleScroll} ref={scrollableContainerElRef}>
            {loadingTopMessages && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: `${loadingComponentHeight}px`,
                }}
              >
                <Loader />
              </div>
            )}
            <div className={style.liveChatMessages}>
              {messages?.map((message) => {
                return (
                  <Message
                    isElection={isElection}
                    key={message.id}
                    message={message}
                    newRepliesAllowed={isRoomOpen}
                    // onRepliesToggle={handleMessageRepliesToggle}
                    // onReplyFormSubmit={handleReplyFormSubmit}
                    // showReplies={Boolean(repliesShownMessageId && repliesShownMessageId === message.id)}
                  />
                );
              })}
            </div>
          </div>

          {showScrollToBottomButton && <ScrollToBottomButton onClick={handleScrollToBottomClick} />}
        </div>

        {isRoomOpen && (
          <div>
            <MessageForm isElection={isElection} onSubmit={handleMessageFormSubmit} />
          </div>
        )}
      </section>
    );
  },
);

LiveChat.displayName = 'LiveChat';
